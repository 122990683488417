<div class="container">
  <article>
    <header>
      <strong>Editar Cliente</strong>
    </header>

    <main>
      <div main class="main">
        <div class="grid-content">
          <app-input
            label="Nome"
            placeholder="Nome completo"
            errorMessage="campo obrigatório"
            [control]="form.controls.name"
            [disabled]="loadingName"
            [error]="!form.controls.name.valid && !form.controls.name.pristine"
            [loading]="loadingName"
          ></app-input>
          <app-select-multi
            label="Status"
            placeholder="Selecione um status"
            [singleControl]="form.controls.active"
            [options]="status"
            [selected]="selectedStatus"
            property="Type"
            [disabled]="loadingStatus"
            errorMessage="campo obrigatório"
            [loading]="loadingStatus"
          ></app-select-multi>
          <app-select-multi
            label="Skill"
            placeholder="Selecione múltiplas skills"
            errorMessage="campo obrigatório"
            [multiControl]="form.controls.skill"
            [options]="skills"
            [selected]="selectedSkills"
            property="Type"
            [disabled]="loadingSkills"
            [multi]="true"
            [loading]="loadingSkills"
            [isSearch]="true"
          ></app-select-multi>
        </div>
        <div class="grid-content">
          <app-input
            label="CNPJ"
            placeholder="Informe o CNPJ do cliente"
            [errorMessage]="cnpjErrorMessage"
            [control]="form.controls.cnpj"
            [disabled]="loadingCNPJ"
            [width]="'23.3rem'"
            [error]="!form.controls.cnpj.valid && !form.controls.cnpj.pristine"
            [mask]="MaskTypeEnum.cnpj"
            (valueChange)="handleCNPJValueChanged($event)"
            [loading]="loadingCNPJ"
          ></app-input>
          <app-input
            label="Tempo Segurando Linha"
            placeholder="hh:mm:ss"
            [control]="form.controls.holdingLineTime"
            [disabled]="loadingHoldingLineTime"
            [mask]="MaskTypeEnum.time"
            [error]="
              !form.controls.holdingLineTime.valid &&
              !form.controls.holdingLineTime.pristine
            "
            [errorMessage]="'Tempo inválido.'"
            [icon]="'assets/icons/time.svg'"
            [paddingTop]="'3.1rem'"
            [loading]="loadingHoldingLineTime"
          ></app-input>
          <app-input
            label="Tempo de Prontidão Excedido"
            placeholder="hh:mm:ss"
            [control]="form.controls.idTimeExceeded"
            [disabled]="loadingIdTimeExceeded"
            [mask]="MaskTypeEnum.time"
            [error]="
              !form.controls.idTimeExceeded.valid &&
              !form.controls.idTimeExceeded.pristine
            "
            [errorMessage]="'Tempo inválido.'"
            [icon]="'assets/icons/time.svg'"
            [paddingTop]="'3.1rem'"
            [loading]="loadingIdTimeExceeded"
          ></app-input>
        </div>
        <div class="grid-content">
          <app-input
            label="Tempo de Retorno Excedido"
            placeholder="hh:mm:ss"
            [control]="form.controls.returnTimeExceeded"
            [disabled]="loadingReturnTimeExceeded"
            [width]="'23.3rem'"
            [mask]="MaskTypeEnum.time"
            [error]="
              !form.controls.returnTimeExceeded.valid &&
              !form.controls.returnTimeExceeded.pristine
            "
            [errorMessage]="'Tempo inválido.'"
            [icon]="'assets/icons/time.svg'"
            [paddingTop]="'3.1rem'"
            [loading]="loadingReturnTimeExceeded"
          ></app-input>
          <app-input
            label="Tempo Mínimo de Ligação"
            placeholder="hh:mm:ss"
            [control]="form.controls.minimumCallTime"
            [disabled]="loadingMinimumCallTime"
            [width]="'23.3rem'"
            [mask]="MaskTypeEnum.time"
            [error]="
              !form.controls.minimumCallTime.valid &&
              !form.controls.minimumCallTime.pristine
            "
            [errorMessage]="'Tempo inválido.'"
            [icon]="'assets/icons/time.svg'"
            [paddingTop]="'3.1rem'"
            [loading]="loadingMinimumCallTime"
          ></app-input>
        </div>

        <div class="shift-config">
          <strong>Configurar Turno</strong>
          <hr />

          <app-icon-button
            *ngIf="!loadingShifts"
            icon="assets/icons/add-shift.svg"
            [color]="ButtonColorEnum.common"
            (click)="addShiftInput()"
            [enabled]="shiftInputs.length < maxShifts"
          ></app-icon-button>
          <img
            *ngIf="!loadingShifts"
            class="accordion-img"
            [ngClass]="{ 'rotate-animation': shiftIsOpen }"
            src="assets/icons/multi-select-arrow.svg"
            (click)="handleToggleAccordion()"
          />
          <div *ngIf="loadingShifts" class="loading">
            <div class="loading-animation"></div>
          </div>
        </div>

        <ng-container *ngFor="let shiftGroup of shiftInputs; let i = index">
          <div class="grid-content-shifts" *ngIf="shiftIsOpen">
            <app-select-multi
              label="Turno"
              placeholder="Selecione um turno"
              [singleControl]="shiftGroup.controls.shiftName"
              [options]="shifts"
              [selected]="
                shiftGroup.controls.shiftName.value != undefined
                  ? [shiftGroup.controls.shiftName.value]
                  : []
              "
              property="Type"
              [disabled]="false"
              [multi]="false"
              (valueChangeSingle)="handleShiftNameChange($event)"
              errorMessage="campo obrigatório"
            ></app-select-multi>

            <app-input
              label="Horário de Entrada"
              [control]="shiftGroup.controls.startTime"
              [disabled]="false"
              [error]="getErrorStartTime(shiftGroup, i)"
              [errorMessage]="errorMessageStartTime"
              [icon]="'assets/icons/time.svg'"
              [paddingTop]="'3.1rem'"
              [type]="InputTypeEnum.time"
            ></app-input>

            <app-input
              label="Horário de Saída"
              [control]="shiftGroup.controls.endTime"
              [disabled]="false"
              [error]="getErrorEndTime(shiftGroup, i)"
              [errorMessage]="errorMessageEndTime"
              [icon]="'assets/icons/time.svg'"
              [paddingTop]="'3.1rem'"
              [type]="InputTypeEnum.time"
            ></app-input>
            <app-icon-button
              icon="assets/icons/trash.svg"
              [color]="ButtonColorEnum.common"
              (click)="removeShiftInput(i)"
              [enabled]="true"
            ></app-icon-button>
          </div>
        </ng-container>
      </div>

      <hr />

      <div footer class="footer">
        <app-secondary-button
          footer
          label="Cancelar"
          [right]="false"
          [color]="ButtonColorEnum.primary"
          (clickFunc)="handleNavigateToList()"
        ></app-secondary-button>
        <app-primary-button
          [label]="'Salvar'"
          [color]="ButtonColorEnum.common"
          [loading]="isLoading"
          [enabled]="form.valid && getShiftsValid()"
          (clickFunc)="handleUpdateClient()"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponse()"
  ></app-primary-button>
</app-modal-response>
