<app-modal
  title="Transcrição"
  [showModal]="showModal"
  (closeModalFunc)="handleClose()"
  [closeIcon]="'assets/icons/red_close.ico'"
>
  <div actions class="actions">
    <app-icon-button
      *ngIf="!audioUrl"
      icon="assets/icons/audio.svg"
      [color]="ButtonColorEnum.primary"
      [loading]="audioLoading"
      (clickFunc)="handleAudio()"
    ></app-icon-button>
    <audio
      *ngIf="audioUrl"
      [ngClass]="{ 'audio-enabled': audioUrl }"
      #AudioEL
      controls
    >
      <source [src]="audioUrl" type="audio/wav" />
    </audio>
  </div>
  <div main class="main">
    <div class="conversation" *ngIf="data.transcription != null" [innerHTML]="data.transcription"></div>
    <div class="null-conversation" *ngIf="data.transcription == null">Não foi possível carregar a transcrição deste atendimento. Tente reprocessá-lo e visualize novamente.</div>
  </div>
  
  <div footer class="footer">
    <div>
      <strong>Data do atendimento</strong>
      <br />
      {{ data.serviceDate | date : "dd/MM/yyyy HH:mm:ss" }}
    </div>
    <div>
      <strong>{{ "Atendente" }}</strong>
      <br />
      {{ data.attendant ? data.attendant.name : "-" }}
    </div>
    <div>
      <strong>{{ "Cliente" }}</strong>
      <br />
      {{ data.client ? data.client.name : "-" }}
    </div>
    <app-secondary-button
      label="Fechar"
      [color]="ButtonColorEnum.primary"
      (clickFunc)="handleClose()"
    ></app-secondary-button>
  </div>
</app-modal>
