import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { GetCategoriesResponse } from '../../service/models/category.service.model';

@Component({
  selector: 'app-category-list-item',
  templateUrl: './category-list-item.component.html',
  styleUrls: ['./category-list-item.component.scss'],
})
export class CategoryListItemComponent {
  constructor(private storageService: StorageService, private router: Router) {}

  hasSupervisorRole: boolean = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  @Input() data: GetCategoriesResponse = {
    id: '',
    name: '',
    color: '',
    active: false,
    creationDate: '',
    keywords: [],
  };

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }

  handleDetails(id: string) {
    this.router.navigate(['/category/', id]);
  }
}
