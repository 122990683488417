<app-modal
  title="Editar usuário"
  (closeModalFunc)="handleCloseModal()"
  [showModal]="showModal"
  [closeIcon]="'assets/icons/red_close.ico'"
>
  <div main class="main">
    <div class="grid-content">
      <app-input
        label="Nome"
        placeholder="Nome completo"
        errorMessage="campo obrigatório"
        [control]="form.controls.name"
        [error]="!form.controls.name.valid && !form.controls.name.pristine"
        [disabled]="false"
      ></app-input>
      <app-select
        label="Perfil"
        placeholder="Selecione um perfil"
        errorMessage="campo obrigatório"
        [options]="roleOptions"
        [control]="form.controls.perfil"
        [error]="!form.controls.perfil.valid && !form.controls.perfil.pristine"
        [disabled]="false"
      ></app-select>
      <app-input
        label="Funcional"
        placeholder="Informe o Funcional"
        errorMessage="campo obrigatório"
        [control]="form.controls.functional"
        [error]="
          !form.controls.functional.valid && !form.controls.functional.pristine
        "
        [mask]="MaskTypeEnum.number"
        [maxlength]="11"
        [disabled]="false"
      ></app-input>
      <app-select
        *ngIf="selectSupervisorEnabled"
        label="Supervisor"
        placeholder="Selecione um supervisor"
        errorMessage="campo obrigatório"
        [options]="supervisors"
        [control]="form.controls.supervisor"
        [error]="
          !form.controls.supervisor.valid && !form.controls.supervisor.pristine
        "
        [disabled]="false"
        [maxOptionsHeight]="'8rem'"
      ></app-select>
      <app-select
        label="Status"
        placeholder="Selecione um Status"
        errorMessage="campo obrigatório"
        [options]="activeOptions"
        [control]="form.controls.active"
        [error]="!form.controls.active.valid && !form.controls.active.pristine"
        [disabled]="false"
      ></app-select>
      <app-input
        label="ID da Gravação"
        placeholder="Informe o código"
        [control]="form.controls.recorderId"
        [error]="!form.controls.recorderId.valid && !form.controls.recorderId.pristine"
        [disabled]="false"
        errorMessage="''"
        ></app-input>
    </div>
    <div class="grid-content">
      <div
        class="email-container"
        *ngIf="form.controls.perfil.value != 'ATENDENTE'"
      >
        <app-input
          *ngIf="form.controls.perfil.value != 'ATENDENTE'"
          label="E-mail"
          placeholder="Ex. user@email.com"
          [errorMessage]="
            form.controls.perfil.value == 'ATENDENTE' ? '' : 'campo obrigatório'
          "
          [control]="form.controls.email"
          [error]="!form.controls.email.valid"
          [disabled]="form.controls.perfil.value == 'ATENDENTE'"
        ></app-input>
      </div>
    </div>
  </div>
  <div footer class="footer">
    <app-secondary-button
      label="Cancelar"
      [color]="ButtonColorEnum.primary"
      (clickFunc)="handleCloseModal()"
    ></app-secondary-button>
    <app-primary-button
      [label]="'Salvar'"
      [color]="ButtonColorEnum.common"
      [loading]="isLoading"
      [enabled]="isFormValid()"
      (clickFunc)="handleUpdateUser()"
    ></app-primary-button>
  </div>
</app-modal>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponse()"
  ></app-primary-button>
</app-modal-response>
