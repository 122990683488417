import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { CategoryService } from '../service/category.service';
import {
  CreateCategoryRequest,
  Keyword,
  KeywordVariation,
} from '../service/models/category.service.model';

const MAX_WORDS_TO_SHOW = 5;

@Component({
  selector: 'app-category-modal-new',
  templateUrl: './category-new.component.html',
  styleUrls: ['./category-new.component.scss'],
})
export class CategoryNewComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public categoryService: CategoryService,
    public router: Router
  ) {}

  isLoading: boolean = false;
  error: boolean = false;
  originalKeywords: Keyword[] = [];
  keywords: string[] = [];
  activeOptions: string[] = ['Ativo', 'Inativo'];
  textareaContent: string = '';
  showAll: boolean = false;
  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';
  selectedKeyword: Keyword | undefined | null = null;

  form = this.formBuilder.group({
    name: ['', Validators.required],
    color: ['', Validators.required],
    active: ['', Validators.required],
    keyword: [''],
    variation: [''],
  });

  ButtonColorEnum = ButtonColorEnum;
  MaskTypeEnum = MaskTypeEnum;
  InputTypeEnum = InputTypeEnum;

  ngOnInit() {}

  isFormValid() {
    return this.form.valid;
  }

  isFormComplete() {
    return (
      this.form.controls.name.valid &&
      this.form.controls.active.valid &&
      this.form.controls.color.valid &&
      this.keywords.length > 0
    );
  }

  closeModalResponse() {
    if (this.error == false) this.closeModal();

    this.showModalResponse = false;
  }

  closeModal() {
    this.form.reset();
    this.router.navigate(['/category/']);
  }

  showSeeAllButton(): boolean {
    return !this.showAll && this.keywords.length > MAX_WORDS_TO_SHOW;
  }

  toggleShowAll(): void {
    this.showAll = !this.showAll;
  }

  addKeyword() {
    const newKeyword = this.form.controls.keyword.value ?? '';

    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    if (
      newKeyword.trim() !== '' &&
      !this.stringExistsInList(newKeyword, allCategoryWords)
    ) {
      this.keywords.push(newKeyword);
      this.form.controls.keyword.reset();
      this.updateKeywordTextarea();
      this.originalKeywords.push({
        name: newKeyword,
        active: true,
        keywordVariations: [],
      } as Keyword);
    }
  }

  addKeywordVariation() {
    const newVariation = this.form.controls.variation.value ?? '';

    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    if (
      newVariation.trim() !== '' &&
      !this.stringExistsInList(newVariation, allCategoryWords)
    ) {
      this.selectedKeyword?.keywordVariations.push({
        name: newVariation,
        active: true,
      } as KeywordVariation);
      this.form.controls.variation.reset();
    }
  }

  normalizeString(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  stringExistsInList(target: string, list: string[]): boolean {
    const normalizedTarget = this.normalizeString(target);
    return list.some((item) => this.normalizeString(item) === normalizedTarget);
  }

  updateKeywordTextarea() {
    if (this.keywords.length <= MAX_WORDS_TO_SHOW || this.showAll)
      this.textareaContent = this.keywords.join(' ');
  }

  visibleKeywords(): string[] {
    if (this.showAll) return this.keywords;
    else return this.keywords.slice(0, MAX_WORDS_TO_SHOW);
  }

  removeKeyword(index: number) {
    var keyword = this.keywords.splice(index, 1)[0];
    this.updateKeywordTextarea();

    this.originalKeywords = this.originalKeywords.filter(
      (k) => k.name != keyword
    );

    if (keyword == this.selectedKeyword?.name) this.selectedKeyword = null;

    if (this.keywords.length <= MAX_WORDS_TO_SHOW) this.showAll = false;
  }

  removeKeywordVariation(index: number) {
    var keyword = this.originalKeywords.find(
      (keyword) => keyword.name == this.selectedKeyword?.name
    );

    keyword?.keywordVariations.splice(index, 1);
  }

  isKeywordValid() {
    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    return (
      this.form.controls.keyword.valid &&
      !this.stringExistsInList(
        this.form.controls.keyword.value ?? '',
        allCategoryWords
      )
    );
  }

  isKeywordVariationValid() {
    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    return (
      this.form.controls.variation.valid &&
      !this.stringExistsInList(
        this.form.controls.variation.value ?? '',
        allCategoryWords
      )
    );
  }

  createCategory() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.error = false;

    var keywords = this.getKeywordsFromText(this.keywords);

    var request = {
      name: this.form.controls.name.value ?? '',
      color: this.form.controls.color.value ?? '',
      active: this.form.controls.active.value === 'Ativo' ? true : false,
      keywords: keywords,
    } as CreateCategoryRequest;

    this.categoryService.createCategory(request).subscribe({
      next: () => {
        this.isLoading = false;
        this.titleModalResponse = 'Nova categoria registrada com sucesso!';
        this.iconButtonModalResponse = 'assets/icons/success-primary.svg';
        this.message = 'nova categoria foi adicionada ao sistema com êxito.';
        this.showModalResponse = true;
        this.form.reset();
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;
        console.error(e);

        this.titleModalResponse = 'Ocorreu um erro ao registrar a categoria';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.showModalResponse = true;

        if (e.error && e.error.includes('categoria já está cadastrada'))
          this.message = 'Esta categoria já está cadastrada.';
        else this.message = 'tente novamente mais tarde.';
      },
    });
  }

  getKeywordsFromText(keywordTexts: string[]): Keyword[] {
    var response: Keyword[] = [];

    keywordTexts.forEach((keywordText) => {
      var keyword = this.originalKeywords.find(
        (originalKeyword) => originalKeyword.name === keywordText
      );
      if (keyword) response.push(keyword);
      else {
        var newKeywork: Keyword = {
          active: true,
          name: keywordText,
          keywordVariations: [],
        };
        response.push(newKeywork);
      }
    });

    return response;
  }

  setSelectedKeyword(keywordName: string) {
    var keyword = this.originalKeywords.find(
      (keyword) => keyword.name == keywordName
    );

    this.selectedKeyword = keyword;
  }
}
