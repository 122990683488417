import { Component, Input } from '@angular/core';
import Chart, { ChartDataset, ChartOptions, ChartTypeRegistry } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { PieChartParams } from './models/pie-chart.model';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent {
  constructor() {}

  currentChart: Chart | undefined;
  chartId: string | undefined;
  @Input() loading!: boolean;
  @Input() error!: boolean;
  @Input() inlineContainer!: boolean;
  @Input() inlineLegend!: boolean;
  options: ChartOptions = {};

  createChart(params: PieChartParams, chartId: string): void {
    this.chartId = chartId;

    setTimeout(() => {
      const ctx = document.getElementById(chartId) as HTMLCanvasElement;

      const datasets: ChartDataset<keyof ChartTypeRegistry>[] = [];

      params.datasets.forEach((dataset) => {
        const convertedData = dataset.values.map((value) => {
          const parsedValue = parseFloat(value);
          return isNaN(parsedValue) ? null : parsedValue;
        });

        datasets.push({
          label: dataset.title,
          data: convertedData,
          backgroundColor: dataset.backgroundColor,
          borderColor: dataset.borderColor,
          borderWidth: dataset.borderWidth,
        });
      });

      if (this.currentChart) this.currentChart.destroy();

      this.configureOptions();
      this.currentChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: params.footerOptions,
          datasets: datasets,
        },
        options: this.options,
        plugins: [ChartDataLabels],
      });
    }, 0);
  }

  configureOptions() {
    this.options = {
      plugins: {
        datalabels: {
          display: true,
          font: {
            weight: 'bold',
            size: 12
          },
          backgroundColor: '#37425f',
          padding: 6,
          borderRadius: 8,
          color: 'white',
          formatter: Math.round,
        },
        legend: {
          position: this.inlineLegend ? 'right' : 'bottom',
          display: true,
        },
      },
      maintainAspectRatio: false,
    };
  }
}
