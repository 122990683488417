import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../../user/service/models/user.service.model';
import {
  GetClientsFilter,
  GetClientsResponse,
  GetSkillRequest,
  GetSkillResponse,
} from '../../client/models/client.model';
import { UserService } from '../../user/service/user.service';
import { CategoryService } from '../../category/service/category.service';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { ClientService } from '../../client/service/client.service';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { GetCategoriesResponse } from '../../category/service/models/category.service.model';
import { GetKeywordsCloudRequest } from '../keywords-cloud.model';

@Component({
  selector: 'app-keywords-cloud-filter',
  templateUrl: './keywords-cloud-filter.component.html',
  styleUrls: ['./keywords-cloud-filter.component.scss'],
})
export class KeywordsCloudFilterComponent {
  @Output() filterEmmiter: EventEmitter<GetKeywordsCloudRequest> =
    new EventEmitter<GetKeywordsCloudRequest>();

  constructor(
    public formBuilder: FormBuilder,
    public storageService: StorageService,
    public userService: UserService,
    public categoryService: CategoryService,
    public skillService: SkillService,
    public clientService: ClientService
  ) {}

  form = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    cloudType: ['', Validators.required],
    attendants: [['']],
    clients: [['']],
    skills: [['']],
    categories: [['']],
    shifts: [['']],
  });

  hasSupervisorRole: boolean = false;
  isValidPeriod: boolean = true;
  isOpen: boolean = true;
  isLoading: boolean = false;
  error: boolean = false;

  attendants: string[] = [];
  selectedAttendants: string[] = [];
  originalAttendants: GetUserResponse[] = [];
  loadingAttendants = false;

  clients: string[] = [];
  selectedClients: string[] = [];
  originalClients: GetClientsResponse[] = [];
  loadingClients = false;

  skills: string[] = [];
  selectedSkills: string[] = [];
  originalSkills: GetSkillResponse[] = [];
  loadingSkills = false;

  categories: string[] = [];
  selectedCategories: string[] = [];
  originalCategories: GetCategoriesResponse[] = [];
  loadingCategories = false;

  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  selectedShifts: string[] = [];
  loadingShifts = false;

  cloudThemes: string[] = [];
  loadingCloudThemes = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  get MaskTypeEnum() {
    return MaskTypeEnum;
  }

  ngOnInit() {
    this.hasSupervisorRole =
      this.storageService.getRole() === UserRoleEnum.supervisor;

    this.form.controls.clients.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0) {
          var clientIds = this.getClientsIdFromName(value);

          var clients = this.originalClients.filter((s) =>
            clientIds.includes(s.id)
          );

          const filteredSkills = clients
            .map((s) => s.skills.map((a) => a))
            .flat();

          this.skills = filteredSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );

          this.selectedSkills = [];
        } else {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
        }

        if (
          !this.form.controls.clients.value ||
          this.form.controls.clients.value.length === 0 ||
          (this.form.controls.clients.value.length === 1 &&
            this.form.controls.clients.value[0] === '')
        ) {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
        }
      }
    );

    this.populateCloudThemes();
    this.populateAttendants();
    this.populateCategories();
    this.populateClients();
    this.populateSkills();
  }

  populateCloudThemes() {
    this.loadingCloudThemes = true;
    this.cloudThemes = ['Analítica', 'Motivo de Contato', 'Ofensores'];
    this.loadingCloudThemes = false;
  }

  populateAttendants() {
    this.loadingAttendants = true;
    this.attendants = [];
    var attendants: string[] = [];

    this.userService
      .get({
        profiles: ['ATENDENTE'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          data.items.forEach((item: GetUserResponse) => {
            attendants.push(item.name + ' | ' + item.functional);
          });

          this.originalAttendants = data.items;
          this.attendants = attendants;
          this.loadingAttendants = false;
        },

        error: (e) => {
          console.error(e);
          this.loadingAttendants = false;
        },
      });
  }

  populateClients() {
    this.loadingClients = true;
    this.clients = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clients = data.items.map((i) => i.name.trim());
          this.loadingClients = false;
        },
      });
  }

  populateSkills() {
    this.loadingSkills = true;
    this.skills = [];

    this.skillService.get({ active: true } as GetSkillRequest).subscribe({
      next: (data) => {
        this.originalSkills = data;
        this.skills = data.map(
          (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
        );
        this.loadingSkills = false;
      },
    });
  }

  populateCategories() {
    this.categories = [];
    this.loadingCategories = true;

    this.categoryService.getAll().subscribe({
      next: (data) => {
        this.originalCategories = data;
        this.categories = data.map((category) => category.name);
        this.loadingCategories = false;
      },
    });
  }

  getAttendantIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [functionalString, name] = text.split('|').map((part) => part.trim());
    var attendant = this.originalAttendants.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name === name
    );

    return attendant ? attendant.id : null;
  }

  getCategoriesIdFromName(categoryNames: string[]): string[] {
    let categoryIds: string[] = [];

    categoryNames.forEach((categoryName) => {
      let categoryId = this.getCategoryIdFromName(categoryName);
      if (categoryId) categoryIds.push(categoryId);
    });

    return categoryIds;
  }

  getClientsIdFromName(clientNames: string[]): string[] {
    let clientIds: string[] = [];

    clientNames.forEach((i) => {
      let clientId = this.getClientIdFromName(i);
      if (clientId) clientIds.push(clientId);
    });

    return clientIds;
  }

  getCategoryIdFromName(name: string): string | null {
    if (!name || name.trim() === '') return null;

    var category = this.originalCategories.find(
      (category) => category.name.trim() === name.trim()
    );

    return category ? category.id : null;
  }

  getClientIdFromName(name: string): string | null {
    if (!name || name.trim() === '') return null;

    var client = this.originalClients.find(
      (i) => i.name.trim() === name.trim()
    );

    return client ? client.id : null;
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  clear() {
    this.form.reset();
    this.selectedAttendants = [];
    this.selectedCategories = [];
    this.selectedClients = [];
    this.selectedSkills = [];
  }

  getAttendantsIdFromText(text: string[]): string[] {
    let attendantIds: string[] = [];

    text.forEach((i) => {
      var [name, functionalString] = i.split('|').map((part) => part.trim());
      var attendant = this.originalAttendants.find(
        (j) =>
          j.functional.toString() === functionalString &&
          j.name.trim() === name.trim()
      );

      if (attendant) attendantIds.push(attendant.id);
    });

    return attendantIds;
  }

  getFilteredShiftsValue(text: string[] | null): string[] | null {
    if (!text || text.length == 0 || (text.length == 1 && text[0] == ''))
      return null;
    return this.form.controls.shifts.value;
  }

  getSkillIdFromText(text: string[]): string[] | null {
    if (!text || text.length === 0) return null;

    var skills: string[] = [];

    for (let i = 0; i < text.length; i++) {
      if (!text[i] || text[i].trim() === '') return null;

      var [code, cellAndSubCell] = text[i].split(':').map((i) => i.trim());
      var [cell, subCell] = cellAndSubCell.split('—').map((i) => i.trim());

      var skillFiltered = this.originalSkills.find(
        (i) =>
          i.code.toString() === code &&
          i.cell.trim() === cell &&
          i.subCell.trim() === subCell
      );

      if (!skillFiltered || skillFiltered == undefined) {
        var clients = this.form.controls.clients;

        if (
          clients &&
          clients.value &&
          clients.value.length > 0 &&
          clients.value[0] != ''
        ) {
          const filteredClients = this.originalClients.filter((client) =>
            clients.value?.includes(client.name)
          );

          skillFiltered = filteredClients
            .flatMap((client) => client.skills)
            .find(
              (skill) =>
                skill.code.toString() === code &&
                skill.cell.trim() === cell &&
                skill.subCell.trim() === subCell
            );

          if (!skillFiltered) return null;
        }
      }

      if (skillFiltered) skills.push(skillFiltered.id);
    }

    return skills;
  }

  isSkillsDisabled(): boolean {
    const clients = this.form.controls.clients.value;
    return !clients || (clients.length === 0 && this.skills.length === 0);
  }

  getSkillsPlaceholder(): string {
    const clients = this.form.controls.clients.value;
    if (clients && clients.length > 0 && clients[0] !== '') {
      return this.skills.length > 0
        ? 'Selecione múltiplas skills'
        : 'Nenhuma skill disponível';
    }
    return 'Selecione múltiplas skills';
  }

  search() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.error = false;
    var formError = false;

    if (
      this.form.controls.cloudType.value == null ||
      this.form.controls.cloudType.value == ''
    ) {
      this.form.controls.cloudType.markAsDirty();
      formError = true;
    }

    if (
      this.form.controls.endDate.value == null ||
      this.form.controls.endDate.value == ''
    ) {
      this.form.controls.endDate.markAsDirty();
      formError = true;
    }

    if (
      this.form.controls.startDate.value == null ||
      this.form.controls.startDate.value == ''
    ) {
      this.form.controls.startDate.markAsDirty();
      formError = true;
    }

    if (formError) {
      this.isLoading = false;
      return;
    }

    let filter: GetKeywordsCloudRequest = {} as GetKeywordsCloudRequest;

    filter.startDate = this.form.controls.startDate.value ?? '';
    filter.endDate = this.form.controls.endDate.value ?? '';

    filter.attendants = this.getAttendantsIdFromText(
      this.form.controls.attendants.value || []
    );

    filter.categories = this.getCategoriesIdFromName(
      this.form.controls.categories.value || []
    );

    filter.clients = this.getClientsIdFromName(
      this.form.controls.clients.value || []
    );

    filter.skills = this.getSkillIdFromText(
      this.form.controls.skills.value || []
    );

    filter.shifts = this.getFilteredShiftsValue(
      this.form.controls.shifts.value
    );

    filter.cloudTheme = this.form.controls.cloudType.value ?? '';

    this.filterEmmiter.emit(filter);

    this.isLoading = false;
    this.isOpen = false;
  }
}
