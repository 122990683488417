<div class="header" [style.gridTemplateColumns]="getGridTemplateColumnsValue()">
  <app-check-box
    *ngIf="hasSupervisorRole"
    class="first-column"
    [value]="isChecked"
    (check)="handleCheck()"
    (removeCheck)="handleUncheck()"
    [loading]="incidentIdsLoading"
    [disabled]="incidentIdsLoading"
  ></app-check-box>
  <strong>PROTOCOLO</strong>
  <strong>ATENDENTE</strong>
  <strong>CATEGORIA</strong>
  <strong
    >DATA DE <br />
    ATENDIMENTO</strong
  >
  <strong>STATUS</strong>
  <strong>% RESOLUÇÃO</strong>
  <strong *ngIf="hasSupervisorRole"></strong>
</div>
