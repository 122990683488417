import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import {
  GetClientsFilter,
  GetClientsResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import { TranscriptionService } from '../../transcription/service/transcription.service';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../../user/service/models/user.service.model';
import { UserService } from '../../user/service/user.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss'],
})
export class DashboardFilterComponent {
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public clientService: ClientService,
    public transcriptionService: TranscriptionService
  ) {}

  @Output() filterEmmiter: EventEmitter<DashboardFilterRequest> =
    new EventEmitter<DashboardFilterRequest>();

  isLoading: boolean = false;
  error: boolean = false;

  clientOptions: string[] = [];
  originalClients: GetClientsResponse[] = [];
  selectedClients: string[] = [];

  cells: string[] = [];
  selectedCells: string[] = [];

  supervisors: string[] = [];
  selectedSupervisors: string[] = [];
  originalSupervisors: GetUserResponse[] = [];

  attendants: string[] = [];
  selectedAttendants: string[] = [];
  originalAttendants: GetUserResponse[] = [];

  subCells: string[] = [];
  selectedSubCells: string[] = [];

  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  selectedShifts: string[] = [];
  loadingShifts = false;

  public showModalResponse: boolean = false;
  public titleModalResponse: string = '';
  public iconButtonModalResponse: string = '';
  public message: string = '';

  form = this.formBuilder.group({
    initialDate: '',
    finalDate: '',
    supervisor: '',
    clients: [[]],
    attendants: [[]],
    cells: [[]],
    subCells: [[]],
    shifts: [['']],
  });

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  ngOnInit() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    this.form.patchValue({
      initialDate: this.formatDate(firstDayOfMonth),
      finalDate: this.formatDate(currentDate),
    });

    this.populateAttendants();
    this.populateSupervisors();
    this.populateClients();
    this.populateCells();
    this.populateSubCells();

    this.filter();
  }

  populateAttendants() {
    this.attendants = [];

    this.userService
      .get({ profiles: ['ATENDENTE'] } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalAttendants = data.items;
          this.attendants = data.items.map(
            (attendant) => attendant.functional + ' | ' + attendant.name
          );
        },
      });
  }

  populateClients() {
    this.clientOptions = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
        },
      });
  }

  getFilteredShiftsValue(text: string[] | null): string[] | null {
    if (!text || text.length == 0 || (text.length == 1 && text[0] == ''))
      return null;
    return this.form.controls.shifts.value;
  }

  populateCells() {
    this.transcriptionService.getCells().subscribe({
      next: (data) => {
        this.cells = data;
      },
    });
  }

  populateSubCells() {
    this.transcriptionService.getSubCells().subscribe({
      next: (data) => {
        this.subCells = data;
      },
    });
  }

  populateSupervisors() {
    this.supervisors = [];

    this.userService
      .get({ profiles: ['SUPERVISOR'] } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalSupervisors = data.items;
          this.supervisors = data.items.map(
            (supervisor) => supervisor.functional + ' | ' + supervisor.name
          );
        },
      });
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  clear() {
    this.form.reset();
    this.selectedClients = [];
    this.selectedAttendants = [];
    this.selectedCells = [];
    this.selectedSubCells = [];
    this.selectedSupervisors = [];
  }

  getClientIdsFromArray(array: string[]): string[] | null {
    if (!array || array.length === 0) return null;

    var clientIds: string[] = [];
    array.forEach((client) => {
      var [cnpj, name] = client.split('|').map((i) => i.trim());
      var objClient = this.originalClients.find((i) => i.cnpj === cnpj);
      if (objClient && objClient.id) clientIds.push(objClient.id);
    });

    return clientIds;
  }

  handleCloseModalResponse() {
    this.showModalResponse = false;
  }

  filter() {
    var clientIds = this.getClientIdsFromArray(
      this.form.controls.clients.value ?? []
    );
    var selectedClients: string[] = this.form.controls.clients.value ?? [];

    if (selectedClients.length > 0) {
      if (
        clientIds === null ||
        clientIds.length === 0 ||
        clientIds.length !== selectedClients.length
      ) {
        this.isLoading = false;
        this.error = true;
        this.titleModalResponse = 'Ocorreu um erro ao filtrar dashboards';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.message = 'não foi possível recuperar os clientes selecionados.';
        this.showModalResponse = true;
        return;
      }
    }

    var shifts = this.getFilteredShiftsValue(
      this.form.controls.shifts.value
    );

    var filters = {
      startDate: this.form.controls.initialDate.value ?? null,
      endDate: this.form.controls.finalDate.value ?? null,
      clients: clientIds,
      cells: this.form.controls.cells.value ?? null,
      supervisor:
        this.getSupervisorIdFromText(
          this.form.controls.supervisor.value || ''
        ) ?? null,
      attendants:
        this.getAttendantsIdFromText(
          this.form.controls.attendants.value || []
        ) ?? null,
      subCells: this.form.controls.subCells.value ?? null,
      shifts: shifts,
    } as DashboardFilterRequest;

    this.filterEmmiter.emit(filters);
  }

  getAttendantsIdFromText(text: string[]): string[] {
    let attendantIds: string[] = [];

    text.forEach((attendantText) => {
      let attendantId = this.getAttendantIdFromText(attendantText);
      if (attendantId) attendantIds.push(attendantId);
    });

    return attendantIds;
  }

  getAttendantIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [functionalString, name] = text.split('|').map((part) => part.trim());
    var attendant = this.originalAttendants.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name.trim() === name.trim()
    );

    return attendant ? attendant.id : null;
  }

  getSupervisorIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [functionalString, name] = text.split('|').map((part) => part.trim());
    var supervisor = this.originalSupervisors.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name.trim() === name.trim()
    );

    return supervisor ? supervisor.id : null;
  }
}
