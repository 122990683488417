import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { CategoryService } from '../service/category.service';
import {
  GetCategoriesFilter,
  GetCategoriesResponse,
} from '../service/models/category.service.model';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent {
  constructor(
    private categoryService: CategoryService,
    private storageService: StorageService,
    public router: Router
  ) {}

  @Input() filter!: GetCategoriesFilter;

  categoryList: GetCategoriesResponse[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  isLoading: boolean = false;
  hasSupervisorRole: boolean = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;

    this.filter = {} as GetCategoriesFilter;
    this.get();
  }

  ngOnChanges() {
    if (this.filter) {
      this.resetPage();
      this.get();
    }
  }

  resetPage() {
    this.page = 1;
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.clearItems();

    const filters = JSON.parse(JSON.stringify(this.filter));

    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.categoryService
      .getCategories(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalPages = data.totalPages;
          this.totalItems = data.totalItems;
          this.setCategoryList(data.items);
        },
        error: () => {
          this.clearItems();
        },
      });
  }

  setCategoryList(data: GetCategoriesResponse[]) {
    if (!data?.length) return;

    this.categoryList = data.map((form) => ({
      id: form.id,
      name: form.name,
      color: form.color,
      active: form.active,
      creationDate: form.creationDate,
      keywords: [],
    }));
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.clearItems();
    this.get();
  }

  clearItems() {
    this.categoryList = [];
  }

  navigateToNewForm() {
    this.router.navigate(['/category/new']);
  }
}
