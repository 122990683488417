import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetKeywordsCloudRequest, GetKeywordsCloudResponse } from './keywords-cloud.model';

@Injectable({
  providedIn: 'root',
})
export class CloudKeywordsService {
  constructor(private http: HttpClient) {}

  private baseURL: string = `${environment.apiUrl}/api/CloudKeywords`;

  private serializeQueryParams(obj: any): any {
    let queryBuilder = new HttpParams();

    Object.keys(obj).forEach((k) => {
      if (Array.isArray(obj[k])) {
        (obj[k] as []).forEach(
          (value) => (queryBuilder = queryBuilder.append(k, value))
        );
      } else if (obj[k]) {
        queryBuilder = queryBuilder.append(k, obj[k]);
      }
    });

    return queryBuilder;
  }

  getAnalytic(
    filter: GetKeywordsCloudRequest
  ): Observable<GetKeywordsCloudResponse[]> {
    const params = this.serializeQueryParams(filter);
    return this.http.get<GetKeywordsCloudResponse[]>(this.baseURL + "/Analytic", {
      params,
    });
  }

  getContactReason(
    filter: GetKeywordsCloudRequest
  ): Observable<GetKeywordsCloudResponse[]> {
    const params = this.serializeQueryParams(filter);
    return this.http.get<GetKeywordsCloudResponse[]>(this.baseURL + "/ContactReason", {
      params,
    });
  }
}
