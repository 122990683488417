<div class="category-item">
  <div class="category-item__column">
    <strong>{{ data.name || "-" }}</strong>
  </div>
  <div class="category-item__column">
    <strong>{{ data.creationDate | date : "dd/MM/yyyy" }}</strong>
  </div>
  <div class="category-item__column">
    <strong>{{ data.active ? "Ativo" : "Inativo" }}</strong>
  </div>
  <div class="incident-item__column actions" *ngIf="hasSupervisorRole">
    <app-icon-button
      icon="../../../../../assets/icons/edit.svg"
      [color]="ButtonColorEnum.primary"
      (clickFunc)="handleDetails(data.id)"
    ></app-icon-button>
  </div>
</div>
