import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { CategoryService } from '../service/category.service';
import {
  Keyword,
  KeywordVariation,
  UpdateCategoryRequest,
} from '../service/models/category.service.model';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
})
export class CategoryDetailsComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public categoryService: CategoryService,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  originalKeywords: Keyword[] = [];
  keywords: string[] = [];
  textareaContent: string = '';
  maxWordsToShow = 5;
  showAll: boolean = false;
  isLoading: boolean = false;
  success: boolean = false;
  error: boolean = false;
  categoryId: string = '';
  activeOptions: string[] = ['Ativo', 'Inativo'];
  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';
  selectedKeyword: Keyword | undefined | null = null;
  selectedStatus: string[] = [];

  form = this.formBuilder.group({
    name: ['', Validators.required],
    color: ['', Validators.required],
    active: ['', Validators.required],
    keyword: [''],
    variation: [''],
  });

  ButtonColorEnum = ButtonColorEnum;
  MaskTypeEnum = MaskTypeEnum;
  InputTypeEnum = InputTypeEnum;

  isFormValid() {
    return this.form.valid;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const categoryIdFromUrl = params['id'];

      if (categoryIdFromUrl) {
        this.categoryId = categoryIdFromUrl;
        this.getCategory();
      }
    });
  }

  loadingCategory = false;

  private getCategory() {
    this.loadingCategory = true;
    this.categoryService
      .getCategoryById(this.categoryId)
      .subscribe((category) => {
        if (category) {
          this.form.patchValue({
            name: category.name,
            active: category.active ? 'Ativo' : 'Inativo',
            color: category.color,
          });

          if (category.keywords.length > 0)
            this.selectedKeyword = category.keywords[0];

          this.selectedStatus = category.active ? ['Ativo'] : ['Inativo'];
          this.keywords = [];
          const keywords = category.keywords.map((keyword) => keyword.name);
          this.originalKeywords = category.keywords;
          this.keywords.push(...keywords);
          this.form.controls.keyword.reset();
          this.updateKeywordTextarea();

          this.loadingCategory = false;
        }
      });
  }

  closeModal() {
    this.form.reset();
    this.router.navigate(['/category/']);
  }

  isKeywordValid() {
    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    return (
      this.form.controls.keyword.valid &&
      !this.stringExistsInList(
        this.form.controls.keyword.value ?? '',
        allCategoryWords
      )
    );
  }

  isKeywordVariationValid() {
    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    return (
      this.form.controls.variation.valid &&
      !this.stringExistsInList(
        this.form.controls.variation.value ?? '',
        allCategoryWords
      )
    );
  }

  addKeyword() {
    const newKeyword = this.form.controls.keyword.value ?? '';

    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    if (
      newKeyword.trim() !== '' &&
      !this.stringExistsInList(newKeyword, allCategoryWords)
    ) {
      this.keywords.push(newKeyword);
      this.form.controls.keyword.reset();
      this.updateKeywordTextarea();
      this.originalKeywords.push({
        name: newKeyword,
        active: true,
        keywordVariations: [],
      } as Keyword);
    }
  }

  addKeywordVariation() {
    const newVariation = this.form.controls.variation.value ?? '';

    var allCategoryWords = this.originalKeywords
      .map((k) => k.keywordVariations)
      .flat()
      .map((x) => x.name);
    allCategoryWords.push(...this.keywords);

    if (
      newVariation.trim() !== '' &&
      !this.stringExistsInList(newVariation, allCategoryWords)
    ) {
      this.selectedKeyword?.keywordVariations.push({
        name: newVariation,
        active: true,
      } as KeywordVariation);
      this.form.controls.variation.reset();
    }
  }

  updateKeywordTextarea() {
    if (this.keywords.length <= this.maxWordsToShow || this.showAll)
      this.textareaContent = this.keywords.join(' ');
  }

  showSeeAllButton(): boolean {
    return !this.showAll && this.keywords.length > this.maxWordsToShow;
  }

  toggleShowAll(): void {
    this.showAll = !this.showAll;
  }

  visibleKeywords(): string[] {
    if (this.showAll) return this.keywords;
    else return this.keywords.slice(0, this.maxWordsToShow);
  }

  removeKeyword(index: number) {
    var keyword = this.keywords.splice(index, 1)[0];
    this.updateKeywordTextarea();

    this.originalKeywords = this.originalKeywords.filter(
      (k) => k.name != keyword
    );

    if (keyword == this.selectedKeyword?.name) this.selectedKeyword = null;

    if (this.keywords.length <= this.maxWordsToShow) this.showAll = false;
  }

  removeKeywordVariation(index: number) {
    var keyword = this.originalKeywords.find(
      (keyword) => keyword.name == this.selectedKeyword?.name
    );

    keyword?.keywordVariations.splice(index, 1);
  }

  isFormComplete() {
    return (
      this.form.controls.name.valid &&
      this.form.controls.active.valid &&
      this.form.controls.color.valid &&
      this.keywords.length > 0
    );
  }

  closeModalResponse() {
    if (this.error == false) {
      this.closeModal();
    } else {
      this.showModalResponse = false;
      window.location.reload();
    }
  }

  getKeywordsFromText(keywordTexts: string[]): Keyword[] {
    var response: Keyword[] = [];

    keywordTexts.forEach((keywordText) => {
      var keyword = this.originalKeywords.find(
        (originalKeyword) => originalKeyword.name === keywordText
      );
      if (keyword) response.push(keyword);
      else {
        var newKeywork: Keyword = {
          active: true,
          name: keywordText,
          keywordVariations: [],
        };
        response.push(newKeywork);
      }
    });

    return response;
  }

  updateCategory() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.error = false;

    var keywords = this.getKeywordsFromText(this.keywords);

    var request = {
      name: this.form.controls.name.value ?? '',
      color: this.form.controls.color.value ?? '',
      active: this.form.controls.active.value === 'Ativo' ? true : false,
      keywords: keywords,
    } as UpdateCategoryRequest;

    this.categoryService.updateCategory(this.categoryId, request).subscribe({
      next: () => {
        this.isLoading = false;
        this.showModalResponse = true;
        this.titleModalResponse = 'Alterações salvas com sucesso!';
        this.iconButtonModalResponse = 'assets/icons/success-primary.svg';
        this.message =
          'suas atualizações foram registradas com êxito no sistema.';
        this.form.reset();
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;
        this.showModalResponse = true;
        this.titleModalResponse = 'Ocorreu um erro ao editar a categoria';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.message = e.error;
      },
    });
  }

  setSelectedKeyword(keywordName: string) {
    var keyword = this.originalKeywords.find(
      (keyword) => keyword.name == keywordName
    );

    this.selectedKeyword = keyword;
  }

  normalizeString(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  stringExistsInList(target: string, list: string[]): boolean {
    const normalizedTarget = this.normalizeString(target);
    return list.some((item) => this.normalizeString(item) === normalizedTarget);
  }
}
