import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { TranscriptionStatusEnum } from '../../service/models/transcription.service.model';
import { TranscriptionService } from '../../service/transcription.service';
import {
  AttendantOrClient,
  ITranscriptionListItemDTO,
  ITranscriptionListItemOutput,
} from './models/transcription-list-item.model';

@Component({
  selector: 'app-transcription-list-item',
  templateUrl: './transcription-list-item.component.html',
  styleUrls: ['./transcription-list-item.component.scss'],
})
export class TranscriptionListItemComponent implements OnInit {
  constructor(
    private router: Router,
    private transcriptionService: TranscriptionService,
    private storageService: StorageService
  ) {}

  showModalResponseDelete: boolean = false;
  titleModalResponseDelete: string = '';
  iconButtonModalResponseDelete: string = '';
  messageModalResponseDelete: string = '';
  isSuccessModalResponseDelete: boolean = false;
  isConfirmQuestionDelete: boolean = false;
  showModalResponseReproc: boolean = false;
  titleModalResponseReproc: string = '';
  iconButtonModalResponseReproc: string = '';
  messageModalResponseReproc: string = '';
  isSuccessModalResponseReproc: boolean = false;
  isConfirmQuestionReproc: boolean = false;
  isDeleting: boolean = false;
  hasSupervisorRole: boolean = false;
  idForDelete: string = '';
  resolutenessBarSize: number | null = null;
  isChecked: boolean = false;

  @Input() data: ITranscriptionListItemDTO = {
    id: '',
    createdOn: '',
    createdBy: '',
    protocolNumber: '',
    mentionedCategories: [],
    serviceDate: '',
    attendant: {} as AttendantOrClient,
    client: {} as AttendantOrClient,
    audioStatus: TranscriptionStatusEnum.PROCESSING,
    transcribeStatus: TranscriptionStatusEnum.PROCESSING,
    resultStatus: TranscriptionStatusEnum.PROCESSING,
    statusName: '',
    resolutenessPercentage: null,
    shortCall: false,
  };

  @Input() incidentIdsToReprocessInput: string[] = [];

  @Output() incidentIdsToReprocessOutput: EventEmitter<string[]> =
    new EventEmitter<string[]>();

  @Output() handleResultRequest: EventEmitter<ITranscriptionListItemOutput> =
    new EventEmitter<ITranscriptionListItemOutput>();

  @Output() handleReloadPage: EventEmitter<any> = new EventEmitter<any>();

  get TranscriptionStatusEnum() {
    return TranscriptionStatusEnum;
  }
  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
    this.resolutenessBarSize = this.data?.resolutenessPercentage
      ? this.data?.resolutenessPercentage * 1.32
      : null;
  }

  ngOnChanges() {
    if (this.incidentIdsToReprocessInput.includes(this.data.id))
      this.isChecked = true;
    else this.isChecked = false;
  }

  handleCloseModalResponseDelete() {
    this.showModalResponseDelete = false;
  }

  handleCloseModalResponseDeleteAndReload() {
    this.showModalResponseDelete = false;
    this.handleReloadPage.emit();
  }

  deleteTranscription() {
    this.isDeleting = true;

    this.transcriptionService
      .delete(this.idForDelete)
      .pipe(
        finalize(() => {
          this.isConfirmQuestionDelete = false;
          this.isDeleting = false;
        })
      )
      .subscribe({
        next: () => {
          this.isSuccessModalResponseDelete = true;
          this.showModalResponseDelete = true;
          this.titleModalResponseDelete = 'Transcrição excluída com sucesso!';
          this.iconButtonModalResponseDelete =
            'assets/icons/success-primary.svg';
          this.messageModalResponseDelete =
            'o registro foi removido com êxito do sistema.';
          (')');
        },
        error: (e) => {
          console.error(e);
          this.isSuccessModalResponseDelete = false;
          this.showModalResponseDelete = true;
          this.titleModalResponseDelete =
            'Ocorreu um erro ao remover a transcrição.';
          this.iconButtonModalResponseDelete = 'assets/icons/error.svg';
          this.messageModalResponseDelete =
            'não foi possível excluir o registro no momento, tente novamente mais tarde ou entre em contato com a administração.';
        },
      });
  }

  handleDeleteTranscription(id: string) {
    this.idForDelete = id;
    this.isConfirmQuestionDelete = true;
    this.showModalResponseDelete = true;
    this.titleModalResponseDelete =
      'Tem certeza que deseja excluir a transcrição?';
    this.iconButtonModalResponseDelete =
      'assets/icons/delete.svg';
    this.messageModalResponseDelete = 'essa ação não pode ser desfeita.';
  }

  handleDetails(id: string) {
    this.router.navigate(['/transcription/', id]);
  }

  getCategoriesToShomOnTooltip() {
    return this.data.mentionedCategories.slice(1);
  }

  handleResult() {
    const { id, serviceDate, attendant, client } = this.data;
    this.handleResultRequest.emit({ id, serviceDate, attendant, client });
  }

  navigateToResult() {
    const { id, serviceDate, attendant, client } = this.data;
    this.router.navigate(['/transcription-result/', id]);
  }

  getGridTemplateColumnsValue() {
    if (this.hasSupervisorRole)
      return '0 repeat(auto-fit, minmax(2.5rem, 1fr))';
    return 'repeat(auto-fit, minmax(2.5rem, 1fr))';
  }

  handleCheck() {
    this.isChecked = true;
    this.incidentIdsToReprocessInput.push(this.data.id);
    this.incidentIdsToReprocessOutput.emit(this.incidentIdsToReprocessInput);
  }

  handleUncheck() {
    this.isChecked = false;
    this.incidentIdsToReprocessInput = this.incidentIdsToReprocessInput.filter(
      (item) => item !== this.data.id
    );

    this.incidentIdsToReprocessOutput.emit(this.incidentIdsToReprocessInput);
  }
}
