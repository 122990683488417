import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { UploaderComponent } from 'src/app/shared/uploader/uploader.component';
import {
  GetUserResponse,
  GetUsersFilter,
  UpdateUserRequest,
} from '../../service/models/user.service.model';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent {
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    private storageService: StorageService
  ) {}

  @Input() showModal: boolean = false;
  @ViewChild('appUploader') uploader!: UploaderComponent;

  isLoading: boolean = false;
  error: boolean = false;
  userId: string = '';
  selectSupervisorEnabled: boolean = true;
  activeOptions: string[] = ['Ativo', 'Inativo'];
  roleOptions: string[] = [];
  supervisors: string[] = [];
  message: string = '';
  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  originalSupervisors: GetUserResponse[] = [];

  form = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    functional: ['', Validators.required],
    supervisor: '',
    active: ['', Validators.required],
    perfil: ['', Validators.required],
    recorderId: [''],
  });

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get MaskTypeEnum() {
    return MaskTypeEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  ngOnInit(): void {
    this.showModal = true;
    this.isLoading = true;

    this.route.params.subscribe((params) => {
      const userIdFromUrl = params['id'];

      if (userIdFromUrl) {
        this.userId = userIdFromUrl;
        this.loadUserData();
      }
    });

    this.form.controls.perfil.valueChanges.subscribe(
      (selectedValue: string | null) => {
        if (selectedValue === 'SUPERVISOR') {
          this.selectSupervisorEnabled = false;
          this.form.get('supervisor')?.clearValidators();
          this.form.get('supervisor')?.updateValueAndValidity();
          this.form.get('email')?.setValidators([Validators.required]);
          this.form.get('email')?.updateValueAndValidity();
        } else if (selectedValue == 'ATENDENTE') {
          this.selectSupervisorEnabled = true;
          this.form.get('supervisor')?.setValidators([Validators.required]);
          this.form.get('supervisor')?.updateValueAndValidity();
          this.form.get('email')?.clearValidators();
          this.form.get('email')?.updateValueAndValidity();
        } else {
          this.selectSupervisorEnabled = true;
          this.form.get('supervisor')?.setValidators([Validators.required]);
          this.form.get('supervisor')?.updateValueAndValidity();
          this.form.get('email')?.setValidators([Validators.required]);
          this.form.get('email')?.updateValueAndValidity();
        }
      }
    );

    this.populateRoleOptions();
    this.populateSupervisors();
  }

  populateRoleOptions() {
    this.userService.getRoles().subscribe({
      next: (roles) => {
        this.roleOptions = roles;

        if (this.storageService.getRole() !== UserRoleEnum.supervisor)
          this.roleOptions = this.roleOptions.filter(
            (role) => role !== 'ATENDENTE'
          );
      },
    });
  }

  populateSupervisors() {
    this.supervisors = [];

    this.userService
      .get({
        profiles: ['SUPERVISOR'],
        status: [StatusEnum.ATIVO]
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalSupervisors = data.items;
          this.supervisors = data.items.map(
            (supervisor) => supervisor.functional + ' | ' + supervisor.name
          );
          this.isLoading = false;
        },
      });
  }

  formatSupervisor(functional: string, name: string): string {
    var nameParts = name.trim().split(' ');
    var firstName = nameParts[0];
    var lastName = nameParts[nameParts.length - 1];

    var formattedSupervisor = `${functional} | ${firstName} ${lastName}`;

    return formattedSupervisor;
  }

  isFormValid() {
    return this.form.valid;
  }

  private loadUserData() {
    this.userService.getById(this.userId).subscribe((user) => {
      if (user) {
        this.form.patchValue({
          name: user.name,
          email: user.email,
          functional: user.functional,
          perfil: user.role.name,
          supervisor: user.supervisor ? user.supervisor.functional + ' | ' + user.supervisor.name : null,
          active: user.active ? 'Ativo' : 'Inativo',
          recorderId: user.recorderId,
        });

        this.isLoading = false;
      }
    });
  }

  handleUpdateUser() {
    if (!this.isFormValid() || this.isLoading) return;

    this.error = false;
    this.isLoading = true;

    const request = {
      id: this.userId,
      name: this.form.controls.name.value ?? '',
      email: this.form.controls.email.value ?? '',
      functional: this.form.controls.functional.value ?? '',
      supervisorId:
        this.getSupervisorIdFromText(
          this.form.controls.supervisor.value || ''
        ) ?? null,
      roleName: this.form.controls.perfil.value ?? '',
      active: this.form.controls.active.value === 'Ativo' ? true : false,
      recorderId: this.form.controls.recorderId.value ?? null,
    } as UpdateUserRequest;

    if (request.roleName == 'ATENDENTE') {
      request.email = null;
    }

    if (request.roleName == 'SUPERVISOR') {
      request.supervisorId = null;
    }

    this.userService.updateUser(this.userId, request).subscribe({
      next: () => {
        this.isLoading = false;
        this.titleModalResponse = 'Alterações salvas com sucesso!';
        this.iconButtonModalResponse = 'assets/icons/success-primary.svg';
        this.showModalResponse = true;
        this.message =
          'suas atualizações foram registradas com êxito no sistema.';
        this.form.reset();
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;

        this.titleModalResponse = 'Ocorreu um erro ao editar usuário';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.message = e.error;
        this.showModalResponse = true;
      },
    });
  }

  getSupervisorIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [functionalString, name] = text.split('|').map((part) => part.trim());
    var supervisor = this.originalSupervisors.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name.trim() === name.trim()
    );

    return supervisor ? supervisor.id : null;
  }

  handleCloseModal() {
    this.form.reset();
    this.router.navigate(['/user/']);
  }

  handleCloseModalResponse() {
    if (this.error == false) this.handleCloseModal();

    this.showModalResponse = false;
  }
}
