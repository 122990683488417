<article>
  <header>
    <strong>Filtro</strong>
  </header>
  <main>
    <div main class="main">
      <div class="grid-content">
        <div class="period">
          <div class="period-input">
            <app-input
              label="Período"
              placeholder="De"
              [control]="form.controls.initialDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
              [width]="'10rem'"
            ></app-input>
            <app-input
              label=""
              placeholder="Até"
              [control]="form.controls.finalDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
              [width]="'10rem'"
            ></app-input>
          </div>
        </div>
        <app-select-multi
          label="Clientes"
          placeholder="Selecione múltiplos clientes"
          [multiControl]="form.controls.clients"
          [options]="clientOptions"
          [selected]="selectedClients"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
        <app-select-multi
          label="Células"
          placeholder="Selecione múltiplas células"
          [multiControl]="form.controls.cells"
          [options]="cells"
          [selected]="selectedCells"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
        <app-select-multi
          label="Supervisor"
          placeholder="Selecione um supervisor"
          [singleControl]="form.controls.supervisor"
          [options]="supervisors"
          [selected]="selectedSupervisors"
          property="Type"
          [disabled]="false"
          [multi]="false"
        ></app-select-multi>
        <app-select-multi
          label="Atendentes"
          placeholder="Selecione múltiplos atendentes"
          [multiControl]="form.controls.attendants"
          [options]="attendants"
          [selected]="selectedAttendants"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
        <app-select-multi
          label="Sub-células"
          placeholder="Selecione múltiplas sub-células"
          [multiControl]="form.controls.subCells"
          [options]="subCells"
          [selected]="selectedSubCells"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
        <app-select-multi
            label="Turnos"
            placeholder="Selecione múltiplos turnos"
            [multiControl]="form.controls.shifts"
            [options]="shifts"
            [selected]="selectedShifts"
            property="Type"
            [disabled]="loadingShifts"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingShifts"
          ></app-select-multi>
      </div>
    </div>
    <hr />
    <div footer class="footer">
      <app-secondary-button
        label="Limpar"
        [color]="ButtonColorEnum.warning"
        [icon]="'assets/icons/trash.svg'"
        [enabled]="true"
        [widthFull]="true"
        (clickFunc)="clear()"
      >
      </app-secondary-button>
      <app-primary-button
        [label]="'Aplicar Filtros'"
        [color]="ButtonColorEnum.common"
        [loading]="isLoading"
        [enabled]="true"
        (clickFunc)="filter()"
      ></app-primary-button>
    </div>
  </main>
</article>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponse()"
  ></app-primary-button>
</app-modal-response>
