<article>
  <header (click)="handleToggleAccordion()">
    <strong>Configurar Relatório - Análise de Categorias</strong>
    <img
      class="accordion-img"
      [ngClass]="{ 'rotate-animation': !isOpen }"
      src="assets/icons/arrow.svg"
    />
  </header>
  <main [ngClass]="{ 'is-collapsed': !isOpen }">
    <div main class="main">
      <div class="grid-content">
        <div class="period">
          <div class="period-input">
            <app-input
              label="Período"
              placeholder="De"
              [control]="form.controls.initialDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
              (valueChange)="resetPeriod()"
            ></app-input>
            <app-input
              label=""
              placeholder="Até"
              [control]="form.controls.finalDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
              (valueChange)="resetPeriod()"
            ></app-input>
          </div>
          <div class="required-fields" *ngIf="!isValidPeriod">
            <img src="assets/icons/error.svg" alt="Error Icon" />
            <span>campos obrigatórios</span>
          </div>
        </div>

        <app-select-multi
          label="Clientes"
          placeholder="Selecione múltiplos clientes"
          [multiControl]="form.controls.clients"
          [options]="clients"
          [selected]="selectedClients"
          property="Type"
          [multi]="true"
          [isSearch]="true"
          [disabled]="loadingClients"
          [loading]="loadingClients"
        ></app-select-multi>

        <app-select-multi
          label="Skills"
          [placeholder]="getSkillsPlaceholder()"
          [multiControl]="form.controls.skills"
          [options]="skills"
          [selected]="selectedSkills"
          property="Type"
          [multi]="true"
          [isSearch]="true"
          [disabled]="loadingSkills"
          [loading]="loadingSkills"
        ></app-select-multi>

        <app-select-multi
          label="Células"
          placeholder="Selecione múltiplas células"
          [multiControl]="form.controls.cells"
          [options]="cells"
          [selected]="[]"
          property="Type"
          [multi]="true"
          [selected]="selectedCells"
          [disabled]="loadingCells"
          [loading]="loadingCells"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Sub-células"
          placeholder="Selecione múltiplas sub-células"
          [multiControl]="form.controls.subcells"
          [options]="subCells"
          property="Type"
          [multi]="true"
          [selected]="selectedSubcells"
          [loading]="loadingSubcells"
          [disabled]="loadingSubcells"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Categorias"
          placeholder="Selecione uma ou mais categorias"
          [multiControl]="form.controls.categories"
          [options]="categories"
          [selected]="selectedCategories"
          property="Type"
          [multi]="true"
          [isSearch]="true"
          [disabled]="loadingCategories"
          [loading]="loadingCategories"
        ></app-select-multi>

        <app-select-multi
          label="Supervisores"
          placeholder="Selecione múltiplos supervisores"
          [multiControl]="form.controls.supervisors"
          [options]="supervisors"
          [selected]="selectedSupervisors"
          property="Type"
          [multi]="true"
          [isSearch]="true"
          [disabled]="loadingSupervisors"
          [loading]="loadingSupervisors"
        ></app-select-multi>

        <app-select-multi
          label="Atendentes"
          [placeholder]="getAttendantsPlaceholder()"
          [multiControl]="form.controls.attendants"
          [options]="attendants"
          [selected]="selectedAttendants"
          property="Type"
          [multi]="true"
          [isSearch]="true"
          [disabled]="loadingAttendants"
          [loading]="loadingAttendants"
        ></app-select-multi>

        <app-select-multi
          label="Turnos"
          placeholder="Selecione múltiplos turnos"
          [multiControl]="form.controls.shifts"
          [options]="shifts"
          [selected]="selectedShifts"
          property="Type"
          [multi]="true"
          [disabled]="loadingShifts"
          [loading]="loadingShifts"
        ></app-select-multi>

        <app-select-multi
          label="Tipos"
          placeholder="Selecione múltiplos tipos"
          [multiControl]="form.controls.types"
          [options]="types"
          [selected]="selectedTypes"
          property="Type"
          [multi]="true"
          [disabled]="false"
        ></app-select-multi>
      </div>
    </div>
    <hr />
    <div footer class="footer">
      <app-secondary-button
        (click)="clear()"
        label="Limpar"
        [color]="ButtonColorEnum.warning"
        [icon]="'assets/icons/trash.svg'"
        [enabled]="true"
        [widthFull]="true"
      >
      </app-secondary-button>
      <app-primary-button
        [label]="'Gerar'"
        [color]="ButtonColorEnum.common"
        [icon]="'assets/icons/download-2.svg'"
        [loading]="isLoading"
        (clickFunc)="generate()"
      ></app-primary-button>
    </div>
  </main>
</article>
