import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ButtonColorEnum } from '../buttons/enums/button-color.enum';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {
  @Input() control = new FormControl({} as File);
  @Input() extensions: string[] = [];
  @Input() label: string = '';
  @Input() description: string = '';
  @ViewChild('uploader') uploader!: ElementRef;

  public fileName: string = '';
  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  handleSelectFile(event: Event) {
    const { files } = event.target as HTMLInputElement;

    if (files && files[0]) {
      const file = files[0];
      const name = files[0].name;
      const extension = name.substring(name.lastIndexOf('.'));

      if (!this.extensions.includes(extension)) return;

      this.control.patchValue(file);
      this.fileName = name;
    }
  }

  handleRemoveFile() {
    const dataTransfer = new DataTransfer();
    this.uploader.nativeElement.files = dataTransfer.files;
    this.control.patchValue({} as File);
    setTimeout(() => {
      this.fileName = '';
    }, 0);
  }

  handleButtonClick(event: MouseEvent) {
    if (this.fileName) {
      event.preventDefault();
    }
  }
}
