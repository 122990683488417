<div class="content">
  <article>
    <header (click)="handleToggleAccordion()">
      <strong>Gerar Relatório Analítico</strong>
      <img
        class="accordion-img"
        [ngClass]="{ 'rotate-animation': !isOpen }"
        src="assets/icons/arrow.svg"
      />
    </header>
    <main [ngClass]="{ 'is-collapsed': !isOpen }">
      <div main class="main">
        <div class="grid-content">
          <div class="period">
            <div class="period-input">
              <app-input
                label="Período"
                placeholder="De"
                [control]="form.controls.initialDate"
                [disabled]="false"
                [type]="InputTypeEnum.date"
                [width]="'12rem'"
                (valueChange)="resetPeriod()"
              ></app-input>
              <app-input
                label=""
                placeholder="Até"
                [control]="form.controls.finalDate"
                [disabled]="false"
                [type]="InputTypeEnum.date"
                [width]="'12rem'"
                (valueChange)="resetPeriod()"
              ></app-input>
            </div>
            <div class="required-fields" *ngIf="!isValidPeriod">
              <img src="assets/icons/error.svg" alt="Error Icon" />
              <span>campos obrigatórios</span>
            </div>
          </div>
          <app-select
            label="Atendente"
            placeholder="Selecione um atendente"
            [options]="attendants"
            [control]="form.controls.attendant"
            [error]="
              !form.controls.attendant.valid &&
              !form.controls.attendant.pristine
            "
            [disabled]="false"
            [width]="'100%'"
          ></app-select>
          <app-select-multi
            label="Categorias"
            placeholder="Selecione uma ou mais categorias"
            [multiControl]="form.controls.categories"
            [options]="categories"
            [selected]="selectedCategories"
            property="Type"
            [disabled]="false"
            [multi]="true"
          ></app-select-multi>
          <app-select-multi
            label="Turnos"
            placeholder="Selecione múltiplos turnos"
            [multiControl]="form.controls.shifts"
            [options]="shifts"
            [selected]="selectedShifts"
            property="Type"
            [disabled]="loadingShifts"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingShifts"
          ></app-select-multi>
        </div>
      </div>
      <hr />
      <div footer class="footer">
        <app-secondary-button
          (click)="clear()"
          label="Limpar"
          [color]="ButtonColorEnum.warning"
          [icon]="'assets/icons/trash.svg'"
          [enabled]="true"
          [widthFull]="true"
        >
        </app-secondary-button>
        <app-primary-button
          [icon]="'assets/icons/analytical-report-btn.svg'"
          [label]="'Gerar Excel'"
          [color]="ButtonColorEnum.common"
          [loading]="isLoading"
          (clickFunc)="generate()"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponse()"
  ></app-primary-button>
</app-modal-response>
