import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { User } from 'src/app/common/services/auth/models/user.model';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import {
  getInitials,
  reduceName,
} from 'src/app/common/utils/string-extensions';
import { ButtonColorEnum } from '../buttons/enums/button-color.enum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  constructor(
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService
  ) {}

  user: User = {} as User;
  username: string = '';
  initials: string = '';
  isUserLogged: boolean = false;
  showSubmenu: boolean = false;
  showGestao: boolean = false;
  showRelatorio: boolean = false;
  hasSupervisorRole: boolean = false;
  role: string = '';

  ngOnInit() {
    this.user = this.storageService.getUser();
    this.username = reduceName(this.user.name);
    this.initials = getInitials(this.username);
    this.isUserLogged =
      !!this.storageService.getToken() && !!this.user.name && !!this.user.email;

    this.role = this.storageService.getRole();
    this.hasSupervisorRole = this.role === UserRoleEnum.supervisor;
  }

  toggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  toggleGestao() {
    this.showGestao = !this.showGestao;
  }

  toggleRelatorio() {
    this.showRelatorio = !this.showRelatorio;
  }

  close() {
    this.showSubmenu = false;
  }

  handleNavigateToHome() {
    this.router.navigate(['home']);
    this.showSubmenu = false;
  }

  handleLogout() {
    this.authService.logout(true);
  }

  handleNavigateToUser() {
    this.router.navigate(['user']);
    this.showSubmenu = false;
  }

  handleNavigateToCategory() {
    this.router.navigate(['category']);
    this.showSubmenu = false;
  }

  handleNavigateToClient() {
    if (this.router.url != '/client') {
      this.storageService.setCurrentPage('1');
      this.router.navigate(['client']);
      this.showSubmenu = false;
    }
  }

  handleNavigateToTranscription() {
    if (this.router.url != '/transcription') {
      this.storageService.setCurrentPage('1');
      this.storageService.setCurrentFilter('');
      this.router.navigate(['transcription']);
      this.showSubmenu = false;
    }
  }

  handleNavigateToDashboard() {
    this.router.navigate(['dashboard']);
    this.showSubmenu = false;
  }

  handleNavigateToKeywordsCloud() {
    this.router.navigate(['keywords-cloud']);
    this.showSubmenu = false;
  }

  handleNavigateToResolutenessReport() {
    this.router.navigate(['resoluteness-report']);
    this.showSubmenu = false;
  }

  handleNavigateToSaReport() {
    this.router.navigate(['sa_report']);
    this.showSubmenu = false;
  }

  handleNavigateToCategoryAnalysisReport() {
    this.router.navigate(['category-analysis-report']);
    this.showSubmenu = false;
  }

  handleNavigateToAnalyticalReportReport() {
    this.router.navigate(['analytical-report']);
    this.showSubmenu = false;
  }
}
