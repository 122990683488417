<div class="container">
  <article>
    <header>
      <strong>Resultados</strong>

      <div actions class="actions">
        <app-icon-button
          *ngIf="!audioUrl"
          icon="assets/icons/audio.svg"
          [color]="ButtonColorEnum.primary"
          [enabled]="audioStatus === TranscriptionStatusEnum.FINALIZED"
          [loading]="
            audioStatus === TranscriptionStatusEnum.PROCESSING || audioLoading
          "
          (clickFunc)="handleAudio()"
        ></app-icon-button>
        <audio
          *ngIf="audioUrl"
          [ngClass]="{ 'audio-enabled': audioUrl }"
          #AudioEL
          controls
        >
          <source [src]="audioUrl" type="audio/wav" />
        </audio>
      </div>
    </header>

    <app-result-tabs *ngIf="transcription.length > 0">
      <app-result-tab title="Indicadores">
        <div
          *ngIf="data.insights?.resolutenessPercentage != null"
          class="resoluteness"
        >
          <div>Resolutividade do Atendimento</div>
          <div class="resoluteness-percentage">
            <div>
              <div class="bar"></div>
              <div
                class="percentage-bar"
                [style.width.px]="resolutenessBarSize"
              ></div>
            </div>
            <div>
              {{ data.insights ? data.insights.resolutenessPercentage : "-" }}%
            </div>
          </div>
        </div>

        <div
          *ngIf="data.insights?.resolutenessInsight != null"
          class="resoluteness-insight"
        >
          <div class="head">
            <img src="assets/icons/insight.svg" alt="" />
            <p class="title">Insights da I.A</p>
          </div>
          {{ data.insights?.resolutenessInsight }}
        </div>

        <hr *ngIf="data.insights?.resolutenessPercentage != null" />

        <div class="content">
          <app-dashboard-big-number
            [title]="'TMA da Ligação'"
            [content]="
              !data.insights || data.insights.callTma === ''
                ? '-'
                : data.insights.callTma
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
          <hr />
          <app-dashboard-big-number
            [title]="'Non Talk'"
            [content]="
              !data.insights || data.insights.nonTalk === ''
                ? '-'
                : data.insights.nonTalk
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
          <hr />
          <app-dashboard-big-number
            [title]="'Tempo de ID Positiva'"
            [content]="
              !data.insights || data.insights.idTimeExceeded === ''
                ? '-'
                : data.insights.idTimeExceeded
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
          <hr />
          <app-dashboard-big-number
            [title]="'Tempo Segurando Linha'"
            [content]="
              !data.insights || data.insights.holdingLineTime === ''
                ? '-'
                : data.insights.holdingLineTime
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
          <hr />
          <app-dashboard-big-number
            [title]="'Tempo de Prontidão Excedido'"
            [content]="
              !data.insights || data.insights.readinessTimeExceeded === ''
                ? '-'
                : data.insights.readinessTimeExceeded
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
          <hr />
          <app-dashboard-big-number
            [title]="'Tempo de Solicitação do Cliente'"
            [content]="
              !data.insights || data.insights.clientRequestTime === ''
                ? '-'
                : data.insights.clientRequestTime
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
          <hr />
          <app-dashboard-big-number
            [title]="'Tempo de Retorno Excedido'"
            [content]="
              !data.insights || data.insights.returnTimeExceeded === ''
                ? '-'
                : data.insights.returnTimeExceeded
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
          <hr />
          <app-dashboard-big-number
            [title]="'Vezes que Solicitou um Momento'"
            [content]="
              !data.insights ? '-' : data.insights.aMomentTimeRequested
            "
            [loading]="loading"
            [maxWidth]="'131px'"
            [backgroundColor]="'transparent'"
            fontColor="#000"
            boxShadow="none"
            [titleFontSize]="'12px'"
            [valueFontSize]="'16px'"
            [titleWhiteSpace]="'normal'"
            [minWidth]="'100px'"
            [height]="'90px'"
          ></app-dashboard-big-number>
        </div>
      </app-result-tab>

      <app-result-tab title="Transcrição">
        <div class="main transcription">
          <div class="title">Transcrição</div>

          <div class="speech-start">
            <strong>Início da fala no atendimento:</strong>
            <span>
              {{ transcription[0].period.split("-")[0] }}
            </span>
          </div>

          <div *ngFor="let conversation of transcription">
            <div class="speech">
              <div class="speech-title">
                <strong>{{ conversation.author | capitalize }}:</strong>
                <span>({{ conversation.period.replace("-", "até") }})</span>
              </div>

              <div
                appHighlightKeywords
                [text]="conversation.content | capitalize"
                [wordsToHighlight]="wordsToHighlight"
              ></div>
            </div>
          </div>
        </div>

        <div class="mentioned-categories-analysis">
          <div class="header" (click)="handleToggleAccordion()">
            <strong>Análise de Categorias Mencionadas</strong>
            <img
              class="accordion-img"
              [ngClass]="{ 'rotate-animation': !isOpen }"
              src="assets/icons/arrow.svg"
            />
          </div>

          <div
            class="body"
            *ngIf="!loading && !mentionedCategoriesAnalysisNeedReprocess"
            [ngClass]="{ 'is-collapsed': !isOpen }"
          >
            <table>
              <tr>
                <th></th>
                <th
                  *ngFor="
                    let category of getMentionedCategoriesAnalysisDataKeys()
                  "
                >
                  {{ category }}
                </th>
              </tr>

              <tr>
                <td>Atendente</td>
                <td
                  *ngFor="
                    let category of getMentionedCategoriesAnalysisDataKeys()
                  "
                  [style.background]="
                    backgroundStyle(
                      mentionedCategoriesAnalysisData[category].color,
                      mentionedCategoriesAnalysisData[category].count,
                      mentionedCategoriesAnalysisData[category].countAttendant
                    )
                  "
                >
                  {{ mentionedCategoriesAnalysisData[category].countAttendant }}
                  menções
                </td>
              </tr>

              <tr>
                <td>Cliente</td>
                <td
                  *ngFor="
                    let category of getMentionedCategoriesAnalysisDataKeys()
                  "
                  [style.background]="
                    backgroundStyle(
                      mentionedCategoriesAnalysisData[category].color,
                      mentionedCategoriesAnalysisData[category].count,
                      mentionedCategoriesAnalysisData[category].countClient
                    )
                  "
                >
                  {{ mentionedCategoriesAnalysisData[category].countClient }}
                  menções
                </td>
              </tr>
            </table>
          </div>

          <div
            *ngIf="!loading && mentionedCategoriesAnalysisNeedReprocess"
            class="main transcription without-content"
          >
            Este atendimento ainda não tem Análise de Categorias Mencionadas.
            Tente reprocessá-lo para visualizar.
          </div>

          <div *ngIf="loading" class="loading">
            <div class="loading-animation"></div>
          </div>
        </div>
      </app-result-tab>

      <app-result-tab title="Categorias Mencionadas">
        <div main class="main-categories">
          <div class="result-modal-body">
            <div *ngIf="loading" class="loading">
              <div class="loading-animation"></div>
            </div>
            <div
              class="none-mentioned-categories"
              *ngIf="data.mentionedCategories.length == 0 && !loading"
            >
              Sem categorias mencionadas.
            </div>
            <div
              class="mentioned-categories-container"
              *ngIf="data.mentionedCategories.length > 0"
            >
              <div
                *ngFor="let mentionedCategory of data.mentionedCategories"
                class="mentioned-categories-list"
              >
                <div
                  class="category-container"
                  [ngStyle]="{
                    'background-color': mentionedCategory.color,
                    color: getTextColor(mentionedCategory.color!)
                  }"
                >
                  <div class="category-name">{{ mentionedCategory.name }}:</div>
                  <div class="category-count">
                    {{ getMentionedCategoriesCount(mentionedCategory) }} menções
                  </div>
                </div>
                <div class="keywords-container">
                  <div
                    class="keyword-container"
                    *ngFor="let keyword of mentionedCategory.keywords"
                    [ngStyle]="{
                      'background-color': getKeywordColor(
                        mentionedCategory.name,
                        keyword.count,
                        mentionedCategory.color!
                      ),
                      color: getTextColor(
                        getKeywordColor(
                          mentionedCategory.name,
                          keyword.count,
                          mentionedCategory.color!
                        )
                      )
                    }"
                  >
                    <div class="keyword-count">
                      {{ keyword.count }} menções: &nbsp;
                    </div>
                    <div class="keyword-name">{{ keyword.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-result-tab>

      <app-result-tab title="Motivo do Contato">
        <div class="result-modal-body">
          <div *ngIf="loading" class="loading">
            <div class="loading-animation"></div>
          </div>

          <div *ngIf="!loading">
            <div class="main-title">
              <strong>Motivo do Contato</strong>
            </div>

            <div *ngIf="data.contactReason != null">
              <div class="main" [innerHTML]="data.contactReason"></div>
            </div>
            <div
              class="unavailable-contact-reason"
              *ngIf="data.contactReason == null"
            >
              <img src="assets/icons/error.svg" alt="Error Icon" />
              <strong> Erro: </strong> Não foi possível gerar o motivo do
              contato.
            </div>
          </div>
        </div>
      </app-result-tab>

      <app-result-tab title="Análise de Sentimento">
        <div class="result-modal-body">
          <div *ngIf="loading" class="loading">
            <div class="loading-animation"></div>
          </div>

          <div>
            <div *ngIf="data.result.trim() != '' && !loading">
              <div class="main-title">
                <strong>Análise de sentimento</strong>
              </div>
              <div class="main" [innerHTML]="data.result"></div>
            </div>
            <div
              class="none-mentioned-categories"
              *ngIf="data.result.trim() == '' && !loading"
            >
              Análise de sentimento não disponível.
            </div>
          </div>
        </div>
      </app-result-tab>
    </app-result-tabs>

    <div
      *ngIf="!loading && !loadingTranscription && transcription.length <= 0"
      class="main transcription without-content"
    >
      Não foi possível carregar a transcrição deste atendimento. Tente
      reprocessá-lo e visualize novamente.
    </div>

    <div *ngIf="loadingTranscription" class="loading">
      <div class="loading-animation"></div>
    </div>

    <hr />
    <div footer class="footer">
      <div>
        <strong>Data do atendimento</strong>
        <br />
        {{ data.serviceDate | date : "dd/MM/yyyy HH:mm:ss" }}

        <div *ngIf="loadingFooter" class="loading-footer">
          <div class="loading-animation"></div>
        </div>
      </div>
      <div>
        <strong>Atendente</strong>
        <br />

        {{ data.attendant }}

        <div *ngIf="loadingFooter" class="loading-footer">
          <div class="loading-animation"></div>
        </div>
      </div>
      <div>
        <strong>Cliente</strong>
        <br />
        {{ data.client }}

        <div *ngIf="loadingFooter" class="loading-footer">
          <div class="loading-animation"></div>
        </div>
      </div>

      <div>
        <strong>Skill</strong>
        <br />
        {{ data.skill }}

        <div *ngIf="loadingFooter" class="loading-footer">
          <div class="loading-animation"></div>
        </div>
      </div>

      <div>
        <strong>Ramal</strong>
        <br />
        {{ data.extension }}

        <div *ngIf="loadingFooter" class="loading-footer">
          <div class="loading-animation"></div>
        </div>
      </div>

      <app-secondary-button
        footer
        label="Voltar"
        [color]="ButtonColorEnum.primary"
        (clickFunc)="handleCloseResults()"
      ></app-secondary-button>
    </div>
  </article>
</div>
