<div
  class="info"
  [style.backgroundColor]="backgroundColor"
  [style.height]="height"
>
  <label
    class="title"
    [style.fontSize]="titleFontSize"
    [style.minWidth]="minWidth"
    [style.whiteSpace]="titleWhiteSpace"
    [style.color]="fontColor"
    >{{ title }}</label
  >

  <div *ngIf="loading" class="loading">
    <div class="loading-animation" [style.borderTopColor]="fontColor"></div>
  </div>

  <label *ngIf="!loading" class="content">
    <div
      class="content-value"
      [style.fontSize]="valueFontSize"
      *ngIf="!isNaN(content) && content !== undefined"
      [style.maxWidth]="maxWidth"
      [style.color]="fontColor"
    >
      {{ content }}
    </div>
    <div *ngIf="isNaN(content) || content === undefined">-</div>
  </label>
</div>
