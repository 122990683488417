import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import {
  ITranscriptionTranscriptionConversation,
  TranscriptionStatusEnum,
} from '../../service/models/transcription.service.model';
import { TranscriptionService } from '../../service/transcription.service';
import {
  IMentionedCategory,
  ITranscriptionResultDTO,
} from './models/transcription-result.model';

const RESOLUTENESS_FULL_BAR_SIZE: number = 653;

@Component({
  selector: 'app-transcription-result',
  templateUrl: './transcription-result.component.html',
  styleUrls: ['./transcription-result.component.scss'],
})
export class TranscriptionResultComponent {
  constructor(
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private transcriptionService: TranscriptionService,
    private router: Router
  ) {}

  id: string = '';
  loading: boolean = true;
  loadingFooter: boolean = true;
  loadingTranscription: boolean = true;
  transcription: ITranscriptionTranscriptionConversation[] = [];
  resolutenessBarSize: number | null = null;
  wordsToHighlight: { key: string; value: string | null }[] = [];
  audioUrl: string = '';
  audioLoading: boolean = false;
  audioStatus: TranscriptionStatusEnum = TranscriptionStatusEnum.PROCESSING;
  isOpen: boolean = true;
  size: number = 0;
  mentionedCategoriesAnalysisNeedReprocess: boolean = false;

  TranscriptionStatusEnum = TranscriptionStatusEnum;

  @ViewChild('AudioEL') audioRef: ElementRef<HTMLAudioElement> =
    {} as ElementRef;

  data: ITranscriptionResultDTO = {
    id: '',
    serviceDate: '',
    attendant: '',
    client: '',
    contactReason: '',
    result: '',
    extension: '',
    skill: '',
    insights: {
      callTma: '',
      nonTalk: '',
      readinessTimeExceeded: '',
      returnTimeExceeded: '',
      aMomentTimeRequested: 0,
      idTimeExceeded: '',
      holdingLineTime: '',
      clientRequestTime: '',
      resolutenessPercentage: null,
      resolutenessInsight: null,
    },
    mentionedCategories: [],
  };

  mentionedCategoriesAnalysisData: any = {};

  ButtonColorEnum = ButtonColorEnum;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const userIdFromUrl = params['id'];

      if (userIdFromUrl) {
        this.id = userIdFromUrl;
        this.loadData();
      }
    });

    this.getTranscription();
  }

  handleCloseResults() {
    this.router.navigate(['/transcription']);
  }

  loadData() {
    var transcriptionResult: ITranscriptionResultDTO = {
      contactReason: '',
      result: '',
      mentionedCategories: [],
      id: '',
      serviceDate: '',
      attendant: '',
      client: '',
      extension: '',
      skill: '',
      insights: null,
    };

    this.transcriptionService.getById(this.id).subscribe({
      next: (data) => {
        transcriptionResult.serviceDate = data.serviceDate ?? '-';
        transcriptionResult.attendant = data.attendant
          ? data.attendant.name
          : '-';
        transcriptionResult.client = data.client ? data.client.name : '-';
        transcriptionResult.extension = data.extension ?? '-';
        transcriptionResult.skill = data.skill
          ? data.skill.code +
            ': ' +
            data.skill.cell +
            ' - ' +
            data.skill.subCell
          : '-';

        this.audioStatus = data.audioStatus;

        this.loadingFooter = false;
      },
      error: (e) => console.error(e),
    });

    this.transcriptionService
      .getResult(this.id)
      .pipe(
        finalize(() => {
          this.setWordsToHighlight();
          this.setMentionedCategoriesAnalysisData();
        })
      )
      .subscribe({
        next: (data) => {
          transcriptionResult.id = this.id;

          var cleanedResult = '';

          if (data.result.includes('Análise de sentimentos:\n\n'))
            cleanedResult = data.result.split('Análise de sentimentos:\n\n')[1];
          else if (data.result.includes('Análise de Sentimento:'))
            cleanedResult = data.result.split('Análise de Sentimento:')[1];
          else cleanedResult = data.result;

          transcriptionResult.contactReason = data.contactReason;
          transcriptionResult.result = cleanedResult;
          (transcriptionResult.insights = {
            callTma: data.insights?.callTma,
            nonTalk: data.insights?.nonTalk,
            readinessTimeExceeded: data.insights?.readinessTimeExceeded,
            returnTimeExceeded: data.insights?.returnTimeExceeded,
            aMomentTimeRequested: data.insights?.aMomentTimeRequested,
            idTimeExceeded: data.insights?.idTimeExceeded,
            holdingLineTime: data.insights?.holdingLineTime,
            clientRequestTime: data.insights?.clientRequestTime,
            resolutenessPercentage: data.insights?.resolutenessPercentage,
            resolutenessInsight: data.insights?.resolutenessInsight,
          }),
            (transcriptionResult.mentionedCategories =
              data.mentionedCategories);

          this.resolutenessBarSize = transcriptionResult.insights
            .resolutenessPercentage
            ? transcriptionResult.insights.resolutenessPercentage *
              (RESOLUTENESS_FULL_BAR_SIZE / 100)
            : null;

          this.mentionedCategoriesAnalysisNeedReprocess =
            transcriptionResult.mentionedCategories.every((category) =>
              category.keywords.every(
                (keyword) =>
                  keyword.countAttendant === 0 && keyword.countClient === 0
              )
            );

          this.loading = false;
        },
        error: (e) => console.error(e),
      });

    this.data = transcriptionResult;
  }

  getMentionedCategoriesCount(mentionedCategory: IMentionedCategory) {
    return mentionedCategory.keywords.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0
    );
  }

  setWordsToHighlight() {
    var aux: { key: string; value: string | null }[] = [];

    this.data.mentionedCategories.map((category) => {
      category.keywords.map((keywork) => {
        keywork.keywordVariations.forEach((variation) => {
          aux.push({ key: variation.name.toLowerCase(), value: category.color });
        });
        aux.push({ key: keywork.name.toLowerCase(), value: category.color });
      });
    });

    this.wordsToHighlight = aux;
  }

  setMentionedCategoriesAnalysisData() {
    this.data.mentionedCategories.sort(
      (a, b) => this.sumOfCounts(b) - this.sumOfCounts(a)
    );

    this.data.mentionedCategories.map(
      (mc) =>
        (this.mentionedCategoriesAnalysisData[mc.name] = {
          color: mc.color,
          count: mc.keywords.reduce(
            (accumulator, currentValue) => accumulator + currentValue.count,
            0
          ),
          countClient: mc.keywords.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.countClient,
            0
          ),
          countAttendant: mc.keywords.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.countAttendant,
            0
          ),
        })
    );
  }

  private sumOfCounts(category: IMentionedCategory): number {
    return category.keywords.reduce((sum, keyword) => sum + keyword.count, 0);
  }

  getTranscription() {
    this.loadingTranscription = true;

    this.transcriptionService.getTranscription(this.id).subscribe({
      next: (data) => {
        if (data.content) {
          this.transcription = data.content;
        }
        this.loadingTranscription = false;
      },
      error: (e) => {
        console.error(e);
        this.loadingTranscription = false;
      },
    });
  }

  handleAudio() {
    if (!this.audioUrl) this.getAudioUri();
  }

  getAudioUri() {
    this.audioLoading = true;
    this.transcriptionService.getAudioUri(this.data.id).subscribe({
      next: (data) => {
        this.getAudio(data);
      },
      error: (e) => {
        this.audioLoading = false;
        console.error(e);
      },
    });
  }

  getAudio(uri: string) {
    this.transcriptionService.getAudioContent(uri).subscribe({
      next: (data) => {
        const file = new File([data], 'media', { type: 'audio/wav' });
        const url = window.URL.createObjectURL(file);
        this.audioUrl = url;
        this.audioLoading = false;

        setTimeout(() => {
          this.audioRef.nativeElement.play();
        }, 0);
      },
      error: (error) => {
        console.error(error);
        this.audioLoading = false;
      },
    });
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  getMentionedCategoriesAnalysisDataKeys() {
    return Object.keys(this.mentionedCategoriesAnalysisData);
  }

  backgroundStyle(
    color: string,
    totalCount: number,
    partialCount: number
  ): string {
    var percentage = (partialCount * 100) / totalCount;
    return `linear-gradient(to right, ${color} ${percentage}%, transparent ${percentage}%)`;
  }

  categoryCountColorMap = new Map<string, Map<number, string>>();

  getKeywordColor(
    categoryId: string,
    count: number,
    baseColor: string
  ): string {
    if (!this.categoryCountColorMap.has(categoryId)) {
      this.categoryCountColorMap.set(categoryId, new Map<number, string>());
    }

    const countColorMap = this.categoryCountColorMap.get(categoryId);

    if (!countColorMap!.has(count)) {
      const color = this.lightenColor(baseColor, countColorMap!.size);
      countColorMap!.set(count, color);
    }

    return countColorMap!.get(count)!;
  }

  lightenColor(color: string, index: number): string {
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);

    const increment = 20 * index;

    r = Math.min(255, r + increment);
    g = Math.min(255, g + increment);
    b = Math.min(255, b + increment);

    const newColor = `#${this.componentToHex(r)}${this.componentToHex(
      g
    )}${this.componentToHex(b)}`;
    return newColor;
  }

  componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  getTextColor(backgroundColor: string): string {
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);

    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    return luminance > 160 ? '#334155' : '#FFFFFF';
  }
}
