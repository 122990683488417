import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import {
  CreateUpdateClientRequest,
  GetSkillRequest,
  GetSkillResponse,
  Shift,
} from '../models/client.model';
import { ClientService } from '../service/client.service';
import { timestamp } from 'rxjs';
import { markAllControlsAsDirty } from 'src/app/form-helper.ts';

@Component({
  selector: 'app-client-new',
  templateUrl: './client-new.component.html',
  styleUrls: ['./client-new.component.scss'],
})
export class ClientNewComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public clientService: ClientService,
    public skillService: SkillService
  ) {}

  form = this.formBuilder.group({
    name: ['', Validators.required],
    active: ['', Validators.required],
    cnpj: ['', Validators.required],
    skill: [['']],
    holdingLineTime: [''],
    idTimeExceeded: [''],
    returnTimeExceeded: [''],
    minimumCallTime: [''],
  });

  originalSkills: GetSkillResponse[] = [];
  skills: string[] = [];
  selectedSkills: string[] = [];
  selectedStatus: string[] = [];
  selectedShifts: string[] = [];
  isLoading: boolean = false;
  status: string[] = ['Ativo', 'Inativo'];
  error: boolean = false;

  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  corporateEmailIsChecked: boolean = false;
  message: string = '';
  cnpjErrorMessage: string = '';

  errorMessageStartTime: string = '';
  errorMessageEndTime: string = '';
  showShiftErrorMessage: boolean = true;
  shiftIsOpen: boolean = false;
  shiftInputs: Array<any> = [];
  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  maxShifts = this.shifts.length;

  ButtonColorEnum = ButtonColorEnum;
  InputTypeEnum = InputTypeEnum;
  MaskTypeEnum = MaskTypeEnum;

  addShiftInput() {
    const shiftGroup = this.formBuilder.group({
      shiftName: [null, Validators.required],
      startTime: [null, Validators.required],
      endTime: [null, Validators.required],
    });
    markAllControlsAsDirty(shiftGroup);
    this.shiftInputs.push(shiftGroup);
    this.shiftIsOpen = true;
  }

  removeShiftInput(index: number) {
    if (this.shiftInputs[index].value.shiftName)
      this.shifts.push(this.shiftInputs[index].value.shiftName);
    this.shiftInputs.splice(index, 1);
    if (this.shiftInputs.length === 0) {
      this.shiftIsOpen = false;
    }
  }

  getErrorStartTime(shiftGroup: any, indexToRemove: number): boolean {
    if (
      !shiftGroup.controls.startTime.value &&
      !shiftGroup.controls.startTime.valid
    ) {
      this.errorMessageStartTime = 'Tempo inválido.';
      return true;
    } else {
      var otherShifts = this.shiftInputs.filter(
        (item, index) => index !== indexToRemove
      );
      const currentShift = this.shiftInputs[indexToRemove];
      const currentStartTime = this.convertTimeToDate(
        currentShift.controls.startTime.value
      );

      for (let i of otherShifts) {
        const startTime = this.convertTimeToDate(i.controls.startTime.value);
        const endTime = this.convertTimeToDate(i.controls.endTime.value);

        if (startTime && endTime && currentStartTime) {
          if (currentStartTime >= startTime && currentStartTime <= endTime) {
            const usedShift = i.controls.shiftName.value;

            if (usedShift) {
              this.errorMessageStartTime = `Esta hora já está sendo utilizada no turno '${usedShift}'.`;
            } else {
              this.errorMessageStartTime =
                'Esta hora já está sendo utilizada em outro turno.';
            }
            return true;
          }
        }
      }

      return false;
    }
  }

  getErrorEndTime(shiftGroup: any, indexToRemove: number): boolean {
    if (!shiftGroup.controls.endTime.value) {
      this.errorMessageEndTime = 'Tempo inválido.';
      return true;
    } else {
      var otherShifts = this.shiftInputs.filter(
        (item, index) => index !== indexToRemove
      );

      const currentShift = this.shiftInputs[indexToRemove];
      const currentEndTime = this.convertTimeToDate(
        currentShift.controls.endTime.value
      );

      for (let i of otherShifts) {
        const startTime = this.convertTimeToDate(i.controls.startTime.value);
        const endTime = this.convertTimeToDate(i.controls.endTime.value);

        if (startTime && endTime && currentEndTime) {
          if (currentEndTime >= startTime && currentEndTime <= endTime) {
            const usedShift = i.controls.shiftName.value;

            if (usedShift) {
              this.errorMessageEndTime = `Esta hora já está sendo utilizada no turno '${usedShift}'.`;
            } else {
              this.errorMessageEndTime =
                'Esta hora já está sendo utilizada em outro turno.';
            }
            return true;
          }
        }
      }
      return false;
    }
  }

  convertTimeToDate(timeString: string): Date | null {
    if (timeString) {
      const [hours, minutes] = timeString.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }
    return null;
  }

  ngOnInit(): void {
    this.form.controls.name.markAsDirty();
    this.form.controls.cnpj.markAsDirty();
    this.cnpjErrorMessage = 'campo obrigatório';
    this.loadSkills();
  }

  handleShiftNameChange(event: any) {
    if (!event.old && event.current) {
      this.shifts = this.shifts.filter((i) => i !== event.current);
      this.showShiftErrorMessage = false;
    } else if (!event.current && event.old) {
      this.shifts.push(event.old);
      this.showShiftErrorMessage = true;
    } else if (event.current && event.old && event.current != event.old) {
      this.shifts = this.shifts.filter((i) => i !== event.current);
      this.shifts.push(event.old);
    }
  }

  getShiftsValid(): boolean {
    return this.shiftInputs.every((i) => i.status == 'VALID');
  }

  handleCNPJValueChanged(event: any) {
    this.cnpjErrorMessage =
      event.length === 0 ? 'campo obrigatório' : 'CNPJ incompleto.';
  }

  pushModalResponse(title: string, iconPath: string, mainMessage: string) {
    this.titleModalResponse = title;
    this.iconButtonModalResponse = iconPath;
    this.showModalResponse = true;
    this.message = mainMessage;
  }

  handleToggleAccordion() {
    this.shiftIsOpen = !this.shiftIsOpen;
  }

  getSkillIdFromText(text: { toString: () => string }[]): string[] | null {
    if (!text || text.length === 0) return null;

    var skills: string[] = [];

    for (let i = 0; i < text.length; i++) {
      if (!text[i] || text[i].toString().trim() === '') return null;

      var [code, cellAndSubCell] = text[i]
        .toString()
        .split(':')
        .map((i) => i.trim());
      var [cell, subCell] = cellAndSubCell.split('—').map((i) => i.trim());

      var skillFiltered = this.originalSkills.find(
        (i) =>
          i.code.toString() === code &&
          i.cell.trim() === cell &&
          i.subCell.trim() === subCell
      );

      if (!skillFiltered) return null;

      skills.push(skillFiltered.id);
    }

    return skills;
  }

  loadSkills() {
    this.isLoading = true;
    this.skills = [];
    this.originalSkills = [];

    var filter: GetSkillRequest = {
      clientId: null,
      active: true,
    };

    this.skillService.get(filter).subscribe({
      next: (data) => {
        this.originalSkills = data;
        this.skills = data.map(
          (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
        );
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;
        console.error(e);

        this.titleModalResponse = 'Ocorreu um erro ao carregar skills';
        this.iconButtonModalResponse = 'assets/icons/error.svg';

        if (e.error && e.error.Erros && e.error.Erros.length > 0)
          this.message = e.error.Erros[0].replace('Email:', '');
        else if (e.message) this.message = e.message;
        else this.message = e.error.Message;

        this.showModalResponse = true;
      },
    });
  }

  clear() {
    this.form.reset();
    this.selectedSkills = [];
  }

  handleNavigateToList() {
    this.router.navigate(['client']);
  }

  stringToTime(timeString: string | null): Date | null | string {
    if (timeString == null || timeString == '') return null;

    timeString = timeString.trim();

    if (timeString.length !== 6) {
      this.pushModalResponse(
        'Erro ao cadastrar cliente',
        'assets/icons/error.svg',
        'Tempo "' + timeString + '" inválido, esperado 6 dígitos.'
      );
      return 'error';
    }

    var hours = parseInt(timeString.substring(0, 2), 10);
    var minutes = parseInt(timeString.substring(2, 4), 10);
    var seconds = parseInt(timeString.substring(4, 6), 10);

    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      isNaN(seconds) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59 ||
      seconds < 0 ||
      seconds > 59
    ) {
      this.pushModalResponse(
        'Erro ao cadastrar cliente',
        'assets/icons/error.svg',
        'Tempo "' +
          timeString +
          '" inválido. Horas, minutos ou segundos fora do intervalo válido.'
      );
      return 'error';
    }

    var formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedTime;
  }

  handleCreateClient() {
    if (!this.form.valid || this.isLoading) return;

    this.isLoading = true;
    this.error = false;

    var skills = this.form.controls.skill.value ?? [];
    var finalSkills = null;

    if (!skills || skills.length > 0) {
      if (skills.length != 1 || skills[0].trim() != '') {
        finalSkills = this.getSkillIdFromText(skills);

        if (!finalSkills || finalSkills.length !== skills.length) {
          this.isLoading = false;
          this.error = true;

          this.titleModalResponse =
            'Ocorreu um erro ao recuperar skill selecionada';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'As skills selecionadas não puderam ser recuperadas.';
          this.showModalResponse = true;
          return;
        }
      }
    }

    var holdingLineTime = this.stringToTime(
      this.form.controls.holdingLineTime.value
    );

    var idTimeExceeded = this.stringToTime(
      this.form.controls.idTimeExceeded.value
    );

    var returnTimeExceeded = this.stringToTime(
      this.form.controls.returnTimeExceeded.value
    );

    var minimumCallTime = this.stringToTime(
      this.form.controls.minimumCallTime.value
    );

    if (
      holdingLineTime == 'error' ||
      idTimeExceeded == 'error' ||
      returnTimeExceeded == 'error' ||
      minimumCallTime == 'error'
    ) {
      this.error = true;
      this.isLoading = false;
      return;
    }

    var shifts: Shift[] = [];

    this.shiftInputs.forEach((i) => {
      var newShift: Shift = { name: i.value.shiftName, startTime: i.value.startTime + ":00", endTime: i.value.endTime + ":59" }
      shifts.push(newShift);
    });

    var request = {
      name: this.form.controls.name.value ?? '',
      active: this.form.controls.active.value === 'Ativo' ? true : false,
      skills: finalSkills,
      cnpj: this.form.controls.cnpj.value ?? '',
      holdingLineTime: holdingLineTime,
      idTimeExceeded: idTimeExceeded,
      returnTimeExceeded: returnTimeExceeded,
      minimumCallTime: minimumCallTime,
      shifts: shifts.length === 0 ? null : shifts,
    } as CreateUpdateClientRequest;

    this.clientService.create(request).subscribe({
      next: () => {
        this.isLoading = false;
        this.titleModalResponse = 'Novo cliente registrado com sucesso!';
        this.iconButtonModalResponse = 'assets/icons/success-primary.svg';
        this.message = 'Novo cliente foi adicionado ao sistema com êxito.';
        this.showModalResponse = true;
        this.form.reset();
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;
        this.pushModalResponse(
          'Ocorreu um erro ao inserir cliente',
          'assets/icons/error.svg',
          e.error
        );
      },
    });
  }

  handleCloseModalResponse() {
    if (this.error == false) this.handleCloseModal();

    this.showModalResponse = false;
  }

  handleCloseModal() {
    this.form.reset();
    this.router.navigate(['/client']);
  }
}
