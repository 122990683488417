<div
  class="transcription-item"
  [style.gridTemplateColumns]="getGridTemplateColumnsValue()"
>
  <div
    *ngIf="hasSupervisorRole"
    class="transcription-item__column first-column"
  >
    <app-check-box
      [value]="isChecked"
      (check)="handleCheck()"
      (removeCheck)="handleUncheck()"
      [disabled]="data.resultStatus === TranscriptionStatusEnum.PROCESSING"
    ></app-check-box>
  </div>
  <div class="transcription-item__column">
    <strong>{{ data.protocolNumber || "-" }}</strong>
  </div>
  <div class="transcription-item__column">
    <span>{{ data.attendant ? data.attendant.name : "-" }}</span>
  </div>
  <div class="transcription-item__column categories-column">
    <div class="categories">
      <div
        class="none-categories"
        *ngIf="data.mentionedCategories.length == 0 && !data.shortCall"
      >
        {{ "Sem categorias" }}
      </div>
      <div
        *ngIf="data.mentionedCategories.length > 0 && !data.shortCall"
        class="word-textarea"
      >
        <div class="firstkeyword">
          {{ data.mentionedCategories[0] }}
        </div>
      </div>
      <div class="none-categories" *ngIf="data.shortCall">
        <div class="firstkeyword">Short Call</div>
      </div>
      <div
        *ngIf="data.mentionedCategories.length > 1 && !data.shortCall"
        class="word-textarea"
      >
        <div class="keyword tooltip">
          +{{ data.mentionedCategories.length - 1 }}
          <span class="tooltip-container">
            <div
              *ngFor="let category of getCategoriesToShomOnTooltip()"
              class="tooltip-text"
            >
              {{ category }}
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="transcription-item__column">
    <span>{{ data.serviceDate | date : "dd/MM/yyyy" }}</span>
  </div>
  <div class="transcription-item__column incident-status">
    <div
      class="status-container"
      [ngClass]="{
        'aguard-analise': data.statusName === 'Aguardando Análise',
        'aguard-info': data.statusName === 'Aguardando Informações',
        finalized: data.statusName === 'Finalizado'
      }"
    >
      <img />
      <strong>
        {{ data.statusName || "-" }}
      </strong>
    </div>
  </div>
  <div *ngIf="resolutenessBarSize != null" class="resoluteness">
    <div class="bar"></div>
    <div class="percentage-bar" [style.width.px]="resolutenessBarSize"></div>
    <div class="percentage">{{ data.resolutenessPercentage }}%</div>
  </div>
  <div *ngIf="resolutenessBarSize == null" class="resoluteness-null">-</div>
  <div class="transcription-item__column actions" *ngIf="hasSupervisorRole">
    <app-icon-button
      icon="assets/icons/result.svg"
      [color]="ButtonColorEnum.primary"
      [enabled]="
        data.resultStatus === TranscriptionStatusEnum.FINALIZED &&
        !data.shortCall &&
        data.statusName != 'Aguardando Análise'
      "
      [loading]="data.resultStatus === TranscriptionStatusEnum.PROCESSING"
      (clickFunc)="navigateToResult()"
      tooltipText="Resultados"
    ></app-icon-button>
    <app-icon-button
      [icon]="'assets/icons/edit_transcription.svg'"
      [width]="'1.125rem'"
      [height]="'1.125rem'"
      [color]="ButtonColorEnum.primary"
      [enabled]="
        data.resultStatus != TranscriptionStatusEnum.PROCESSING &&
        data.statusName === 'Aguardando Informações' &&
        !data.shortCall
      "
      (clickFunc)="handleDetails(data.id)"
      tooltipText="Editar"
    ></app-icon-button>
    <app-icon-button
      *ngIf="hasSupervisorRole"
      [icon]="'assets/icons/delete.svg'"
      [color]="ButtonColorEnum.primary"
      [enabled]="
        data.resultStatus != TranscriptionStatusEnum.PROCESSING &&
        data.statusName != 'Aguardando Análise'
      "
      (clickFunc)="handleDeleteTranscription(data.id)"
      tooltipText="Excluir"
    ></app-icon-button>
  </div>
</div>

<app-modal-response
  [showModal]="showModalResponseDelete"
  [title]="titleModalResponseDelete"
  (closeModalFunc)="handleCloseModalResponseDelete()"
  [icon]="iconButtonModalResponseDelete"
>
  <strong main> {{ messageModalResponseDelete }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponseDelete()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="isSuccessModalResponseDelete && !isConfirmQuestionDelete"
    class="btn-ok"
    [label]="'Ok'"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponseDeleteAndReload()"
  ></app-primary-button>

  <app-primary-button
    footer
    *ngIf="isConfirmQuestionDelete"
    class="btn-ok"
    [label]="'Excluir'"
    [right]="false"
    [loading]="isDeleting"
    [color]="ButtonColorEnum.common"
    (clickFunc)="deleteTranscription()"
  ></app-primary-button>
</app-modal-response>
