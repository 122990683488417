import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeywordCloudResponse } from '../../category-analysis-report/service/models/keyword-cloud.service.model';
import {
  CloudData,
  CloudOptions,
  ZoomOnHoverOptions,
} from 'angular-tag-cloud-module';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { CategoryAnalysisReportService } from '../../category-analysis-report/service/category-analysis-report.service';
import { finalize } from 'rxjs';
import { GetKeywordsCloudRequest } from '../keywords-cloud.model';
import { CloudKeywordsService } from '../keywords-cloud.service';

@Component({
  selector: 'app-keywords-cloud-list',
  templateUrl: './keywords-cloud-list.component.html',
  styleUrls: ['./keywords-cloud-list.component.scss'],
})
export class KeywordsCloudListComponent implements OnChanges {
  @Input() filter!: GetKeywordsCloudRequest;

  constructor(
    public router: Router,
    public cloudKeywordsService: CloudKeywordsService
  ) {}

  keywordCloud: KeywordCloudResponse[] = [];
  data: CloudData[] = [];
  loading: boolean = false;
  noContent = false;
  noContentValue = '';

  zoomOnHoverOptions: ZoomOnHoverOptions = {
    scale: 1.1,
    transitionTime: 0.2,
    delay: 0.1,
  };

  options: CloudOptions = {
    width: 1100,
    height: 300,
  };

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnChanges() {
    if (this.filter) this.getKeywordCloud();
  }

  getKeywordCloud() {
    this.loading = true;
    this.keywordCloud = [];
    this.noContent = false;

    const filters = JSON.parse(JSON.stringify(this.filter));

    if (filters.cloudTheme == 'Analítica') {
      this.cloudKeywordsService
        .getAnalytic(filters)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: (data) => {
            if (data == null || data.length == 0) {
              this.noContentValue =
                'Não há resultados para o filtro informado.';
              this.noContent = true;
            } else {
              this.keywordCloud = data;
              this.getData();
            }
          },
        });
    } else if (filters.cloudTheme == 'Motivo de Contato') {
      this.cloudKeywordsService
      .getContactReason(filters)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (data) => {
          if (data == null || data.length == 0) {
            this.noContentValue =
              'Não há resultados para o filtro informado.';
            this.noContent = true;
          } else {
            this.keywordCloud = data;
            this.getData();
          }
        },
      });
    } else {
      this.loading = false
      this.noContentValue =
        'Este tipo de nuvem de palavras não está disponível no momento.';
      this.noContent = true;
    }
  }

  getData() {
    const topN = 50;

    //possíveis cores pra n ficar cores mt estranhas, claras demais, etc
    const colors = [
      'rgb(22, 100, 123)',
      'rgb(45, 150, 230)',
      'rgb(75, 200, 150)',
      'rgb(200, 75, 75)',
      'rgb(150, 45, 230)',
      'rgb(230, 150, 45)',
    ];

    let mappedData = this.keywordCloud
      .sort((a, b) => b.quantity - a.quantity)
      .slice(0, topN)
      .map((keyword) => {
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        return {
          text: keyword.name,
          weight: keyword.quantity,
          color: randomColor,
        } as CloudData;
      });

    this.data = mappedData;
  }
}
