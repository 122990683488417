<div class="container">
  <article>
    <header>
      <strong>Configurar Relatório - Laudo de Resolutividade</strong>
    </header>
    <main>
      <div main class="main">
        <div class="grid-content">
          <div class="period">
            <div class="period-input">
              <app-input
                label="Período"
                placeholder="De"
                [control]="form.controls.initialDate"
                [disabled]="false"
                [type]="InputTypeEnum.date"
                [width]="'10rem'"
                (valueChange)="resetPeriod()"
              ></app-input>
              <app-input
                label=""
                placeholder="Até"
                [control]="form.controls.finalDate"
                [disabled]="false"
                [type]="InputTypeEnum.date"
                [width]="'10rem'"
                (valueChange)="resetPeriod()"
              ></app-input>
            </div>
            <div class="required-fields" *ngIf="!isValidPeriod">
              <img src="assets/icons/error.svg" alt="Error Icon" />
              <span>campos obrigatórios</span>
            </div>
          </div>

          <app-select-multi
            label="Clientes"
            placeholder="Selecione múltiplos clientes"
            [multiControl]="form.controls.clients"
            [options]="clients"
            [selected]="selectedClients"
            property="Type"
            [disabled]="loadingClients"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingClients"
          ></app-select-multi>

          <app-select-multi
            label="Skills"
            [placeholder]="getSkillsPlaceholder()"
            [multiControl]="form.controls.skills"
            [options]="skills"
            [selected]="selectedSkills"
            property="Type"
            [disabled]="isSkillsDisabled() || loadingSkills"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingSkills"
          ></app-select-multi>

          <app-select-multi
            label="Supervisores"
            placeholder="Selecione múltiplos supervisores"
            [multiControl]="form.controls.supervisors"
            [options]="supervisors"
            [selected]="selectedSupervisors"
            property="Type"
            [disabled]="loadingSupervisors"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingSupervisors"
          ></app-select-multi>

          <app-select-multi
            label="Atendentes"
            [placeholder]="getAttendantsPlaceholder()"
            [multiControl]="form.controls.attendants"
            [options]="attendants"
            [selected]="selectedAttendants"
            property="Type"
            [disabled]="isAttendantsDisabled()"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingAttendants"
          ></app-select-multi>

          <app-select-multi
            label="Categorias"
            placeholder="Selecione uma ou mais categorias"
            [multiControl]="form.controls.categories"
            [options]="categories"
            [selected]="selectedCategories"
            property="Type"
            [disabled]="loadingCategories"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingCategories"
          ></app-select-multi>

          <app-select-multi
          label="Turnos"
          placeholder="Selecione múltiplos turnos"
          [multiControl]="form.controls.shifts"
          [options]="shifts"
          [selected]="selectedShifts"
          property="Type"
          [disabled]="loadingShifts"
          [multi]="true"
          [isSearch]="true"
          [loading]="loadingShifts"
        ></app-select-multi>

        </div>
      </div>
      <hr />
      <div footer class="footer">
        <app-secondary-button
          (click)="clear()"
          label="Limpar"
          [color]="ButtonColorEnum.warning"
          [icon]="'assets/icons/trash.svg'"
          [enabled]="true"
          [widthFull]="true"
        >
        </app-secondary-button>
        <app-primary-button
          [label]="'Gerar'"
          [color]="ButtonColorEnum.common"
          [loading]="isLoading"
          (clickFunc)="generate()"
          [enabled]="
            !loadingSupervisors &&
            !loadingAttendants &&
            !loadingCategories &&
            !loadingClients &&
            !loadingSkills
          "
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>

<div class="container" *ngIf="showResults">
  <article>
    <header>
      <div class="sub-title">
        <strong>Resolutividade de Atendimentos no Período</strong>
        <label>{{ startDateFilter }} á {{ endDateFilter }}</label>
      </div>
      <div class="resoluteness">
        <div>
          <div>
            <div class="bar" [style.width.px]="resolutenessFullBarSize"></div>
            <div
              class="percentage-bar"
              [style.width.px]="resolutenessBarSize"
            ></div>
          </div>
          <div class="percentage">{{ averageResolutenessPercentage }}</div>
        </div>
      </div>
      <div class="resume-title">
        <img src="assets/icons/ia_insights.svg" alt="" />
        <label>Insights da I.A</label>
      </div>
      <div class="resume">
        <p>
          {{ resolutenessInsightResume }}
        </p>
      </div>
    </header>
    <main>
      <div class="footer-sub">
        <div class="footer-item">
          <strong>Total de Atendimentos Analisados</strong>
          <label>{{ totalIncidents }}</label>
        </div>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ messageModalResponse }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>
</app-modal-response>
