import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { CategoryService } from '../category/service/category.service';
import { GetCategoriesResponse } from '../category/service/models/category.service.model';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../user/service/models/user.service.model';
import { UserService } from '../user/service/user.service';
import { AnalyticalReportService } from './service/analytical-report.service';
import { AnalyticalReportFilter } from './service/models/analytical-report.model';

@Component({
  selector: 'app-analytical-report',
  templateUrl: './analytical-report.component.html',
  styleUrls: ['./analytical-report.component.scss'],
})
export class AnalyticalReportComponent {
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public userService: UserService,
    public categoryService: CategoryService,
    public analyticalReportService: AnalyticalReportService
  ) {}

  isLoading: boolean = false;
  isOpen: boolean = true;
  attendants: string[] = [];
  categories: string[] = [];
  selectedCategories: string[] = [];
  originalAttendants: GetUserResponse[] = [];
  originalCategories: GetCategoriesResponse[] = [];

  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';
  error: boolean = false;
  isValidPeriod: boolean = true;
  
  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  selectedShifts: string[] = [];
  loadingShifts = false;

  form = this.formBuilder.group({
    initialDate: ['', Validators.required],
    finalDate: ['', Validators.required],
    attendant: '',
    categories: [['']],
    shifts: [['']],
  });

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit() {
    this.populateAttendants();
    this.populateCategories();
  }

  populateAttendants() {
    this.attendants = [];

    this.userService
      .get({
        profiles: ['ATENDENTE'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalAttendants = data.items;
          this.attendants = data.items.map(
            (attendant) => attendant.functional + ' | ' + attendant.name
          );
        },
      });
  }

  getFilteredShiftsValue(text: string[] | null): string[] | null {
    if (!text || text.length == 0 || (text.length == 1 && text[0] == ''))
      return null;
    return this.form.controls.shifts.value;
  }

  populateCategories() {
    this.categories = [];

    this.categoryService.getAll().subscribe({
      next: (data) => {
        this.originalCategories = data;
        this.categories = data.map((category) => category.name);
      },
    });
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  generate() {
    this.isLoading = true;
    this.error = false;

    let filter: AnalyticalReportFilter = {} as AnalyticalReportFilter;

    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';

    this.isValidPeriod = this.isValidDate(filter.startDate, filter.endDate);

    if (!this.isValidPeriod) {
      this.isLoading = false;
      return;
    }

    filter.attendant = this.getAttendantIdFromText(
      this.form.controls.attendant.value ?? ''
    );

    filter.categories = this.getCategoriesIdFromName(
      this.form.controls.categories.value || []
    );

    filter.shifts = this.getFilteredShiftsValue(
      this.form.controls.shifts.value
    );

    this.analyticalReportService
      .generateReport(filter)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          if (data.status === 204) {
            this.isLoading = false;
            this.error = true;
            this.titleModalResponse =
              'Não há resultados para gerar o relatório';
            this.iconButtonModalResponse = 'assets/icons/error.svg';
            this.showModalResponse = true;
          } else {
            this.handleReportDownloadSuccess(data.body);
          }
        },
        error: () => {
          this.error = true;
          this.showModalResponse = true;
          this.titleModalResponse = 'Não foi possível gerar o relatório.';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'o relatório não possui dados o suficiente.';
        },
      });
  }

  getAttendantIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [functionalString, name] = text.split('|').map((part) => part.trim());
    var attendant = this.originalAttendants.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name === name
    );

    return attendant ? attendant.id : null;
  }

  getCategoriesIdFromName(categoryNames: string[]): string[] {
    let categoryIds: string[] = [];

    categoryNames.forEach((categoryName) => {
      let categoryId = this.getCategoryIdFromName(categoryName);
      if (categoryId) categoryIds.push(categoryId);
    });

    return categoryIds;
  }

  getCategoryIdFromName(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var category = this.originalCategories.find((c) => c.name === text);

    return category ? category.id : null;
  }

  handleReportDownloadSuccess(reportFile: Blob | null) {
    const fileAnchor = document.createElement('a');
    const data = new Date();
    fileAnchor.download = `RelatorioAnalitico-${data
      .getDate()
      .toString()
      .padStart(2, '0')}_${(data.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}_${data.getFullYear()}_${data.getHours()}_${data.getMinutes()}.xlsx`;

    fileAnchor.href = window.URL.createObjectURL(reportFile as Blob);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
  }

  isFormComplete() {
    return (
      this.form.controls.initialDate.valid && this.form.controls.finalDate.valid
    );
  }

  clear() {
    this.form.reset();
    this.selectedCategories = [];
  }

  handleCloseModalResponse() {
    if (this.error == false) this.handleCloseModal();

    this.showModalResponse = false;
  }

  handleCloseModal() {
    this.form.reset();
    this.router.navigate(['/analytical-report']);
  }

  resetPeriod() {
    this.isValidPeriod = this.isValidDate(
      this.form.controls.initialDate.value ?? '',
      this.form.controls.finalDate.value ?? ''
    );
  }

  isValidDate(startDate: string, endDate: string) {
    return startDate != '' && endDate != '';
  }
}
