<div class="user-item">
  <div class="user-item__column">
    <strong>{{ data.name || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.email || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.role.name || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.functional || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.recorderId || "-" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{ data.creationDate | date : "dd/MM/yyyy" }}</strong>
  </div>
  <div class="user-item__column">
    <strong>{{getSupervisorName()}}</strong>
  </div>
  <div class="user-item__column status">
    <strong [ngClass]="{ green: data.active, red: !data.active }">{{
      data.active ? "Ativo" : "Inativo" || "-"
    }}</strong>
  </div>
  <div class="user-item__column actions" *ngIf="hasSupervisorRole">
    <app-icon-button
      icon="assets/icons/edit.svg"
      *ngIf="hasSupervisorRole"
      [color]="ButtonColorEnum.primary"
      (click)="handleDetails(data.id)"
    ></app-icon-button>
  </div>
</div>
