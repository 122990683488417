import {
  Component,
  HostListener,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ResizedEvent } from 'angular-resize-event';

import { Observable, Subscription } from 'rxjs';
import { SideIconTypeEnum } from 'src/app/shared/input/enums/side-icon-type.enum';

@Component({
  selector: 'app-select-multi',
  templateUrl: './select-multi.component.html',
  styleUrls: ['./select-multi.component.scss'],
})
export class SelectMultiComponent implements OnInit, OnChanges {
  @Input() multiControl = new FormControl(Array<string>());
  @Input() singleControl = new FormControl('');
  @Input() options: Array<string> = [];
  @Input() errorMessage: string = '';
  @Input() selected: string[] = [];
  @Input() multi: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() value: string = '';
  @Input() disabled: boolean = false;
  @Input() description!: string;
  @Input() id: string = '';
  @Input() fieldVisible: boolean = true;
  @Input() height: number = 3;
  @Input() isSearch: boolean = false;
  @Input() loading: boolean = false;

  @Output() statusChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() selectedItemsEmpty: EventEmitter<any> = new EventEmitter();
  @Output() valueChangeSingle: EventEmitter<any | null> = new EventEmitter();

  eSideIconType = SideIconTypeEnum;

  tabObservable$!: Observable<Event>;
  tabSubscription$!: Subscription;

  filtredOptions: Array<any> = [];
  error: boolean = false;
  onFocus = false;

  width = 25;

  heightSelect = `${(this.height * 0.67).toString()}rem`;

  search = new FormControl('');
  checkedAll: boolean = false;

  oldSingleValue: string | null = '';

  constructor() {}

  @HostListener('document:keydown', ['$event'])
  documentKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.blurInput();
    }
  }

  ngOnInit(): void {
    this.handleForm();

    this.singleControl.statusChanges.subscribe(
      this.controlStatusChanges.bind(this)
    );
    this.multiControl.statusChanges.subscribe(
      this.controlStatusChanges.bind(this)
    );

    if (this.singleControl.status === 'INVALID' || this.multiControl.status === 'INVALID')
      this.error = true;
  }

  controlStatusChanges() {
    this.statusChanged.emit(this.selected);

    if (this.multi) {
      this.error = this.multiControl.status === 'INVALID';
      if (this.multiControl.value && this.multiControl.value.length > 0) {
        this.multiControl.markAsDirty();
      }
    } else {
      this.error = this.singleControl.status === 'INVALID';
      if (this.singleControl.value) {
        this.singleControl.markAsDirty();
      }
    }
  }

  ngOnChanges(): void {
    this.filterOptions(this.value);
    this.valueChangeSingle.emit({
      old: this.oldSingleValue,
      current: this.singleControl.value,
    });
    this.oldSingleValue = this.singleControl.value;
  }

  onResized(event: any) {
    this.width = event?.newRect?.width;
  }

  focusInput() {
    if (!this.disabled) this.onFocus = true;
  }

  blurInput() {
    this.onFocus = false;
    this.search.setValue('');
    this.filterOptions('');
  }

  isChecked(optionSelected: string): boolean {
    return this.selected.some((option) => option === optionSelected);
  }

  selectedEmpty(event: Event) {
    if (this.selected?.length === 0) {
      this.selectedItemsEmpty.emit(event);
    }
  }

  removeAll(event: Event) {
    this.checkedAll = false;
    this.selected = [];
    this.handleForm();
    this.resetFormControl();
    this.selectedEmpty(event);
  }

  checkOption(option: string) {
    this.checkedAll = false;
    if (!this.multi) {
      this.selected = [];
    }

    this.selected.push(option);

    if (!this.multi) {
      this.blurInput();
    }

    this.handleForm();
  }

  checkAll() {
    this.checkedAll = true;
    this.selected = [];
    this.resetFormControl();
    this.filtredOptions.forEach((element) => {
      this.selected.push(element);
    });

    this.handleForm();
  }

  removeOption(optionSelected: string, event: Event) {
    this.checkedAll = false;
    this.selected = this.selected.filter((option) => option !== optionSelected);
    this.handleForm();
    this.search.setValue('');
    this.filterOptions('');
    if (event) event.stopPropagation();
  }

  removeOptionAll(event: Event) {
    this.checkedAll = false;
    this.selected = [];
    this.resetFormControl();
    this.handleForm();
    this.search.setValue('');
    this.filterOptions('');
    event.stopPropagation();
  }

  filterOptions(searchValue: string) {
    if (searchValue !== '') {
      this.filtredOptions = this.options.filter((option) =>
        option.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      this.filtredOptions = this.options;
    }
  }

  handleForm() {
    this.multiControl.setValue(this.selected);
    this.singleControl.setValue(this.selected[0]);

    if (this.selected.length === 0) {
      this.selectedItemsEmpty.emit();
    }
  }

  private resetFormControl() {
    this.multiControl.setValue(['']);
    this.singleControl.setValue('');
  }
}
