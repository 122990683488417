import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { TranscriptionService } from '../../service/transcription.service';
import { AttendantOrClient } from '../transcription-list-item/models/transcription-list-item.model';
import { ITranscriptionTranscriptionDTO } from './models/transcription-modal-transcription.model';

@Component({
  selector: 'app-transcription-modal-transcription',
  templateUrl: './transcription-modal-transcription.component.html',
  styleUrls: ['./transcription-modal-transcription.component.scss'],
})
export class TranscriptionModalTranscriptionComponent implements OnChanges {
  @Input() showModal: boolean = false;
  @Input() data: ITranscriptionTranscriptionDTO = {
    id: '',
    serviceDate: '',
    attendant: {} as AttendantOrClient,
    client: {} as AttendantOrClient,
    transcription: '',
  };
  @Output() handleShowModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild('AudioEL') audioRef: ElementRef<HTMLAudioElement> =
    {} as ElementRef;

  isLoading: boolean = false;
  audioUrl: string = '';
  audioLoading: boolean = false;

  ButtonColorEnum = ButtonColorEnum;

  constructor(private transcriptionService: TranscriptionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue?.id) {
      this.audioUrl = '';
    }
  }

  getAudioUri() {
    this.audioLoading = true;
    this.transcriptionService.getAudioUri(this.data.id).subscribe({
      next: (data) => {
        this.getAudio(data);
      },
      error: (e) => {
        this.audioLoading = false;
        console.error(e);
      },
    });
  }

  getAudio(uri: string) {
    this.transcriptionService.getAudioContent(uri).subscribe({
      next: (data) => {
        const file = new File([data], 'media', { type: 'audio/wav' });
        const url = window.URL.createObjectURL(file);
        this.audioUrl = url;
        this.audioLoading = false;

        setTimeout(() => {
          this.audioRef.nativeElement.play();
        }, 0);
      },
      error: (error) => {
        console.error(error);
        this.audioLoading = false;
      },
    });
  }

  handleAudio() {
    // Get and set audio url
    if (!this.audioUrl) this.getAudioUri();
  }

  handleClose() {
    this.handleShowModal.emit(false);
  }

  getFullTime(time: string) {
    return time ? new Date(+time * 1000).toISOString().slice(11, 19) : '?';
  }
}
