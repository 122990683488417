import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import {
  GetClientsFilter,
  GetClientsResponse,
} from 'src/app/pages/client/models/client.model';
import { ClientService } from 'src/app/pages/client/service/client.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { CategoryService } from '../../service/category.service';
import {
  DuplicateCategoryRequest,
  GetCategoriesResponse,
} from '../../service/models/category.service.model';

@Component({
  selector: 'app-category-list-item',
  templateUrl: './category-list-item.component.html',
  styleUrls: ['./category-list-item.component.scss'],
})
export class CategoryListItemComponent {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private categoryService: CategoryService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService
  ) {}

  hasSupervisorRole: boolean = false;

  showModalResponseDelete: boolean = false;
  titleModalResponseDelete: string = '';
  iconButtonModalResponseDelete: string = '';
  messageModalResponseDelete: string = '';
  isSuccessModalResponseDelete: boolean = false;
  isConfirmQuestionDelete: boolean = false;
  idForDelete: string = '';
  isDeleting: boolean = false;
  activeOptions: string[] = ['Ativo', 'Inativo'];

  clientOptions: string[] = [];
  loadingClients: boolean = false;
  originalClients: GetClientsResponse[] = [];

  form = this.formBuilder.group({
    name: ['', Validators.required],
    client: ['', Validators.required],
  });

  ButtonColorEnum = ButtonColorEnum;

  @Input() data: GetCategoriesResponse = {
    id: '',
    name: '',
    color: '',
    active: false,
    creationDate: '',
    client: null,
    keywords: [],
    updatedOn: null,
    isDeleted: false,
  };

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;

    const showCategoryDuplicateSuccessMessage = localStorage.getItem(
      'showCategoryDuplicateSuccessMessage'
    );

    if (showCategoryDuplicateSuccessMessage) {
      this.toastrService.success('Categoria duplicada com sucesso!');
      localStorage.removeItem('showCategoryDuplicateSuccessMessage');
    }
  }

  populateClients() {
    this.clientOptions = [];
    this.loadingClients = true;

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
          this.loadingClients = false;
        },
        error: () => {
          this.loadingClients = false;
          this.toastrService.error('Não foi possível carregar clientes.');

          this.showModalLoadClientsError = true;
        },
      });
  }

  getStatus(): string {
    if (this.data.isDeleted) return 'Excluído';
    return this.data.active ? 'Ativo' : 'Inativo';
  }

  handleDetails(id: string) {
    this.router.navigate(['/category/', id]);
  }

  showModalDuplicate: boolean = false;

  handleOpenDuplicateModal(name: string) {
    this.showModalDuplicate = true;
    this.form.controls.name.patchValue(name);
    this.populateClients();
  }

  handleCloseModalDuplicate() {
    this.showModalDuplicate = false;
    this.form.reset();
  }

  handleCloseModalDuplicateResponse() {
    this.showModalDuplicateResponse = false;
  }

  handleView(id: string) {
    this.router.navigate(['/category/view', id]);
  }

  handleShowModalDelete(id: string) {
    this.idForDelete = id;
    this.isConfirmQuestionDelete = true;
    this.showModalResponseDelete = true;
    this.titleModalResponseDelete =
      'Tem certeza que deseja excluir a categoria?';
    this.iconButtonModalResponseDelete = 'assets/icons/delete.svg';
    this.messageModalResponseDelete = 'essa ação não pode ser desfeita.';
  }

  handleCloseModalDelete() {
    this.showModalResponseDelete = false;
  }

  handleCloseModalDeleteAndReload() {
    this.showModalResponseDelete = false;
    window.location.reload();
  }

  handleCloseModalDuplicateAndReload() {
    this.showModalDuplicate = false;
    window.location.reload();
  }

  deleteCategory() {
    this.isDeleting = true;

    this.categoryService
      .delete(this.idForDelete)
      .pipe(
        finalize(() => {
          this.isConfirmQuestionDelete = false;
          this.isDeleting = false;
        })
      )
      .subscribe({
        next: () => {
          this.isSuccessModalResponseDelete = true;
          this.showModalResponseDelete = true;
          this.titleModalResponseDelete = 'Categoria excluída com sucesso!';
          this.iconButtonModalResponseDelete =
            'assets/icons/success-primary.svg';
          this.messageModalResponseDelete =
            'o registro foi removido com êxito do sistema.';
          (')');
        },
        error: (e) => {
          console.error(e);
          this.isSuccessModalResponseDelete = false;
          this.showModalResponseDelete = true;
          this.titleModalResponseDelete =
            'Ocorreu um erro ao remover a categoria.';
          this.iconButtonModalResponseDelete = 'assets/icons/error.svg';
          this.messageModalResponseDelete =
            'não foi possível excluir o registro no momento, tente novamente mais tarde ou entre em contato com a administração.';
        },
      });
  }

  showModalLoadClientsError: boolean = false;

  closeModalResponse() {
    this.showModalLoadClientsError = false;
  }

  loadingDuplicateCategory: boolean = false;
  titleModalDuplicate: string = '';
  iconButtonModalDuplicate: string = '';
  messageModalDuplicate: string = '';
  isSuccessDuplicate: boolean = false;
  showModalDuplicateResponse: boolean = false;

  duplicateCategory(id: string) {
    if (this.loadingDuplicateCategory) return;

    this.loadingDuplicateCategory = true;

    var request = {
      name: this.form.controls.name.value ?? '',
      clientId: this.getClientIdFromText(this.form.controls.client.value) ?? '',
    } as DuplicateCategoryRequest;

    this.categoryService.duplicate(id, request).subscribe({
      next: () => {
        this.form.reset();
        this.loadingDuplicateCategory = false;
        this.showModalDuplicateResponse = true;
        this.showModalDuplicate = false;
        this.titleModalDuplicate = 'Categoria duplicada com sucesso';
        this.iconButtonModalDuplicate = 'assets/icons/success-primary.svg';
        this.messageModalDuplicate = '';
        this.isSuccessDuplicate = true;
      },
      error: (e) => {
        this.loadingDuplicateCategory = false;
        this.showModalDuplicateResponse = true;
        this.titleModalDuplicate = 'Não foi possível duplicar a categoria';
        this.iconButtonModalDuplicate = 'assets/icons/error.svg';
        this.messageModalDuplicate = e.error ?? '';
        this.isSuccessDuplicate = false;
      },
    });
  }

  getClientIdFromText(text: string | null): string | null {
    if (!text || text.trim() === '') return null;

    var [cnpj, _] = text.split('|').map((part) => part.trim());
    var client = this.originalClients.find((i) => i.cnpj === cnpj);

    return client ? client.id : null;
  }
}
