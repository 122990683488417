import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranscriptionService } from '../service/transcription.service';
import { UserService } from 'src/app/pages/user/service/user.service';
import { ClientService } from 'src/app/pages/client/service/client.service';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GetUserResponse,
  GetUsersFilter,
} from 'src/app/pages/user/service/models/user.service.model';
import {
  GetClientsFilter,
  GetClientsResponse,
  GetSkillResponse,
} from 'src/app/pages/client/models/client.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-transcription-new',
  templateUrl: './transcription-new.component.html',
  styleUrls: ['./transcription-new.component.scss'],
})
export class TranscriptionNewComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public transcriptionService: TranscriptionService,
    public userService: UserService,
    public clientService: ClientService,
    public skillService: SkillService,
    private storageService: StorageService,
    private toastrService: ToastrService,
  ) {}

  transcriptionId: string = '';
  loadingRequest: boolean = false;

  attendantOptions: string[] = [];
  selectedAttendant: string[] = [];
  selectedAttendantId: string = '';
  originalAttendants: GetUserResponse[] = [];
  loadingAttendants: boolean = true;

  clientOptions: string[] = [];
  selectedClient: string[] = [];
  selectedClientId: string = '';
  originalClients: GetClientsResponse[] = [];
  loadingClients: boolean = true;

  skillOptions: string[] = [];
  selectedSkill: string[] = [];
  selectedSkillId: string = '';
  originalSkills: GetSkillResponse[] = [];
  loadingSkills: boolean = true;

  form = this.formBuilder.group({
    monitor: [''],
    serviceDateTime: ['', Validators.required],
    protocolNumber: ['', Validators.required],
    attendantId: ['', Validators.required],
    extension: [''],
    clientId: ['', Validators.required],
    skillId: ['', Validators.required],
    audio: [{} as File, Validators.required],
  });

  error: boolean = false;

  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';

  ngOnInit(): void {
    var userName = this.storageService.getUser().name;
    this.form.controls.monitor.setValue(userName);

    this.form.controls.clientId.valueChanges.subscribe(
      (value: string | null) => {
        if (value) {
          var clientId = this.getClientIdFromText(value);

          this.skillOptions =
            this.originalClients
              .find((i) => i.id === clientId)
              ?.skills.map((i) => i.code + ': ' + i.cell + ' — ' + i.subCell) ??
            [];
        } else {
          this.skillOptions = [];
          this.selectedSkill = [];
        }
      }
    );

    this.populateAttendants();
    this.populateClients();
  }

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  public get MaskTypeEnum() {
    return MaskTypeEnum;
  }

  handleNavigateToList() {
    this.router.navigate(['transcription']);
  }

  populateAttendants() {
    this.attendantOptions = [];

    this.userService
      .get({
        profiles: ['ATENDENTE'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalAttendants = data.items;
          this.attendantOptions = data.items.map(
            (attendant) => attendant.functional + ' | ' + attendant.name
          );
          this.loadingAttendants = false;
        },
        error: (e) => {
          this.loadingAttendants = false;
          this.error = true;
          console.error(e);

          this.titleModalResponse = 'Ocorreu um erro ao carregar atendentes';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'tente novamente mais tarde.';
          this.showModalResponse = true;
        },
      });
  }

  populateClients() {
    this.clientOptions = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
          this.loadingClients = false;
          this.loadingSkills = false;
        },
        error: (e) => {
          this.loadingClients = false;
          this.loadingSkills = false;
          this.error = true;
          console.error(e);

          this.titleModalResponse = 'Ocorreu um erro ao carregar clientes';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'tente novamente mais tarde.';
          this.showModalResponse = true;
        },
      });
  }

  getClientIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [cnpj, name] = text.split('|').map((part) => part.trim());
    var client = this.originalClients.find((i) => i.cnpj === cnpj);

    return client ? client.id : null;
  }

  getSkillIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [code, cellAndSubCell] = text.split(':').map((part) => part.trim());
    var [cell, subCell] = cellAndSubCell.split('—').map((part) => part.trim());
    var skills = this.originalClients.map((i) => i.skills).flat();

    var skill = skills.find(
      (i) =>
        i.code.toString() === code &&
        i.cell.trim() === cell &&
        i.subCell.trim() === subCell
    );
    return skill ? skill.id : null;
  }

  getSkillIdFromTextAndClientId(text: string, clientId: string): string | null {
    if (!text || text.trim() === '' || !clientId || clientId.trim() === '')
      return null;

    var objClient = this.originalClients.find((i) => i.id === clientId);

    if (!objClient) return null;

    var [code, cellAndSubCell] = text.split(':').map((part) => part.trim());
    var [cell, subCell] = cellAndSubCell.split('—').map((part) => part.trim());

    var skill = objClient.skills.find(
      (i) =>
        i.code.toString() === code &&
        i.cell.trim() === cell &&
        i.subCell.trim() === subCell
    );
    return skill ? skill.id : null;
  }

  getAttendantIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;
    var [functional, name] = text.split('|').map((part) => part.trim());
    var attendant = this.originalAttendants.find(
      (i) => i.functional.toString() === functional
    );

    return attendant ? attendant.id : null;
  }

  // handleAttendantStatusChanged(event: any) {
  //   var id = this.getAttendantIdFromText(event[0]);
  //   this.selectedAttendantId = id ?? '';
  // }

  // handleSkillStatusChanged(event: any) {
  //   var id = this.getSkillIdFromText(event[0]) ?? '';
  //   this.selectedSkillId = id;
  // }

  // handleClientStatusChanged(event: any) {
  //   var id = this.getClientIdFromText(event[0]) ?? '';
  //   if (event[0] != undefined) this.skillErrorMessage = 'campo obrigatório';
  //   else this.skillErrorMessage = 'requer cliente selecionado';
  //   this.selectedSkillId = '';
  //   this.selectedSkill = [];
  //   this.form.controls.skillId.reset();
  //   this.selectedClientId = id;
  //   this.skillOptions =
  //     this.originalClients
  //       .find((i) => i.id === id)
  //       ?.skills.map((i) => i.code + ': ' + i.cell + ' — ' + i.subCell) ?? [];
  // }

  handleCreateTranscription() {
    
    if (this.loadingRequest) return;

    this.loadingRequest = true;
    this.error = false;

    console.log(this.form.controls.attendantId.value);
    console.log(this.form.controls.clientId.value);
    console.log(this.form.controls.skillId.value);

    var attendantId = this.getAttendantIdFromText(
      this.form.controls.attendantId.value ?? ''
    );
    var clientId = this.getClientIdFromText(
      this.form.controls.clientId.value ?? ''
    );
    var skillId = this.getSkillIdFromTextAndClientId(
      this.form.controls.skillId.value ?? '',
      clientId ?? ''
    );

    var formData: FormData = new FormData();
    formData.append('monitor', this.form.controls.monitor.value ?? '');
    formData.append('skill', skillId ?? '');
    formData.append('protocolNumber', this.form.controls.protocolNumber.value ?? '');
    formData.append('serviceDate', this.form.controls.serviceDateTime.value ?? '');
    formData.append('audio', this.form.controls.audio.value ?? '');
    formData.append('attendant', attendantId ?? '');
    formData.append('extension', this.form.controls.extension.value ?? '');

    this.transcriptionService.submit(formData).subscribe({
      next: () => {
        this.loadingRequest = false;
        this.toastrService.success("Atendimento registrado com sucesso");
        this.router.navigate(["transcription"]);
      },
      error: (e) => {
        this.loadingRequest = false;
        this.error = true;
        console.error(e);

        this.titleModalResponse = 'Ocorreu um erro ao registrar atendimento';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.message = e.error.title ?? 'descrição não especificada.';
        this.showModalResponse = true;
      },
    });
  }

  isSkillDisabled(): boolean {
    var client = this.form.controls.clientId;
    return (
      !client ||
      !client.value ||
      client.value.length == 0 ||
      (client.value.length == 1 && client.value[0] === '')
    );
  }

  getSkillPlaceholder(): string {
    var client = this.form.controls.clientId;
    if (
      client &&
      client.value &&
      client.value.length > 0 &&
      client.value[0] !== ''
    ) {
      return this.skillOptions.length > 0
        ? 'Selecione a skill'
        : 'Nenhuma skill disponível';
    }
    return 'Requer cliente selecionado';
  }

  handleCloseModalResponse() {
    if (this.error == false) this.handleCloseModal();

    this.showModalResponse = false;
  }

  handleCloseModal() {
    this.form.reset();
    this.router.navigate(['/transcription']);
  }
}
