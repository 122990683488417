import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { UploaderComponent } from 'src/app/shared/uploader/uploader.component';
import { CategoryService } from '../../category/service/category.service';
import { GetCategoriesResponse } from '../../category/service/models/category.service.model';
import {
  GetClientsFilter,
  GetClientsResponse,
  GetSkillRequest,
  GetSkillResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import { ModalDisplayRefreshModel } from '../../transcription/transcription-list/modal-display-refresh.model';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../../user/service/models/user.service.model';
import { UserService } from '../../user/service/user.service';
import { CreateSaReportRequest } from '../service/models/sa_report.service.model';
import { SaReportService } from '../service/sa_report.service';

@Component({
  selector: 'app-sa_report-modal-new',
  templateUrl: './sa_report-new.component.html',
  styleUrls: ['./sa_report-new.component.scss'],
})
export class SaReportNewComponent implements OnInit {
  @Output() handleShowModal: EventEmitter<ModalDisplayRefreshModel> =
    new EventEmitter<ModalDisplayRefreshModel>();
  @ViewChild('appUploader') uploader!: UploaderComponent;

  public isLoading: boolean = false;
  public error: boolean = false;
  public currentIdSelectedForGenerate: string = '';
  isValidPeriod: boolean = true;

  supervisors: string[] = [];
  selectedSupervisors: string[] = [];
  originalSupervisors: GetUserResponse[] = [];
  loadingSupervisors: boolean = false;

  attendants: string[] = [];
  selectedAttendants: string[] = [];
  originalAttendants: GetUserResponse[] = [];

  categories: string[] = [];
  selectedCategories: string[] = [];
  originalCategories: GetCategoriesResponse[] = [];
  loadingCategories = false;

  clients: string[] = [];
  selectedClients: string[] = [];
  originalClients: GetClientsResponse[] = [];
  loadingClients = false;

  skills: string[] = [];
  selectedSkills: string[] = [];
  originalSkills: GetSkillResponse[] = [];
  loadingSkills = false;

  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  selectedShifts: string[] = [];
  loadingShifts = false;

  public form = this.formBuilder.group({
    initialDate: ['', Validators.required],
    finalDate: ['', Validators.required],
    attendants: [['']],
    supervisors: [['']],
    clients: [['']],
    skills: [['']],
    categories: [['']],
    shifts: [['']],
  });

  public showAll: boolean = false;
  public showModalResponse: boolean = false;
  public titleModalResponse: string = '';
  public iconButtonModalResponse: string = '';
  public message: string = '';

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  public get MaskTypeEnum() {
    return MaskTypeEnum;
  }

  public get InputTypeEnum() {
    return InputTypeEnum;
  }

  constructor(
    public formBuilder: FormBuilder,
    public sa_reportService: SaReportService,
    public userService: UserService,
    public router: Router,
    public categoryService: CategoryService,
    public skillService: SkillService,
    public clientService: ClientService
  ) {}

  ngOnInit(): void {
    this.form.controls.supervisors.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0) {
          var supervisorIds = this.getSupervisorsIdFromText(value);

          var supervisors = this.originalSupervisors.filter((s) =>
            supervisorIds.includes(s.id)
          );

          this.originalAttendants = supervisors
            .map((s) => s.attendants.map((a) => a))
            .flat();

          this.attendants = this.originalAttendants.map(
            (a) => a.name.trim() + ' | ' + a.functional
          );
        } else {
          this.attendants = [];
          this.selectedAttendants = [];
        }
      }
    );

    this.form.controls.clients.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0 && value[0] !== '') {
          var clientIds = this.getClientsIdFromName(value);

          var clients = this.originalClients.filter((s) =>
            clientIds.includes(s.id)
          );

          const filteredSkills = clients
            .map((s) => s.skills.map((a) => a))
            .flat();

          this.skills = filteredSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );

          this.selectedSkills = [];
        } else {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
        }

        if (
          !this.form.controls.clients.value ||
          this.form.controls.clients.value.length === 0 ||
          (this.form.controls.clients.value.length === 1 &&
            this.form.controls.clients.value[0] === '')
        ) {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
        }
      }
    );

    this.populateSupervisors();
    this.populateCategories();
    this.populateClients();
    this.populateSkills();
  }

  handleCloseModalResponse() {
    if (this.error == false) this.handleCloseModal();

    this.showModalResponse = false;
  }

  handleCloseModal() {
    this.form.reset();
    this.router.navigate(['/sa_report/']);
  }

  isSkillsDisabled(): boolean {
    const clients = this.form.controls.clients.value;
    return !clients || (clients.length === 0 && this.skills.length === 0);
  }

  getSkillsPlaceholder(): string {
    const clients = this.form.controls.clients.value;
    if (clients && clients.length > 0 && clients[0] !== '') {
      return this.skills.length > 0
        ? 'Selecione múltiplas skills'
        : 'Nenhuma skill disponível';
    }
    return 'Selecione múltiplas skills';
  }

  isAttendantsDisabled(): boolean {
    var supervisors = this.form.controls.supervisors;
    return (
      !supervisors ||
      !supervisors.value ||
      supervisors.value.length == 0 ||
      (supervisors.value.length == 1 && supervisors.value[0] === '')
    );
  }

  getAttendantsPlaceholder(): string {
    var supervisors = this.form.controls.supervisors;
    if (
      supervisors &&
      supervisors.value &&
      supervisors.value.length > 0 &&
      supervisors.value[0] !== ''
    ) {
      return this.attendants.length > 0
        ? 'Selecione múltiplos atendentes'
        : 'Nenhum atendente disponível';
    }
    return 'Selecione múltiplos atendentes';
  }

  populateClients() {
    this.loadingClients = true;
    this.clients = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clients = data.items.map((i) => i.name.trim());
          this.loadingClients = false;
        },
      });
  }

  populateSkills() {
    this.loadingSkills = true;
    this.skills = [];

    this.skillService.get({ active: true } as GetSkillRequest).subscribe({
      next: (data) => {
        this.originalSkills = data;
        this.skills = data.map(
          (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
        );
        this.loadingSkills = false;
      },
    });
  }

  populateCategories() {
    this.categories = [];
    this.loadingCategories = true;

    this.categoryService.getAll().subscribe({
      next: (data) => {
        this.originalCategories = data;
        this.categories = data.map((category) => category.name);
        this.loadingCategories = false;
      },
    });
  }

  populateSupervisors() {
    this.loadingSupervisors = true;
    this.supervisors = [];

    this.userService
      .get({
        profiles: ['SUPERVISOR'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalSupervisors = data.items;
          this.supervisors = data.items.map(
            (supervisor) =>
              supervisor.name.trim() + ' | ' + supervisor.functional
          );
          this.loadingSupervisors = false;
        },
      });
  }

  getSupervisorsIdFromText(text: string[]): string[] {
    let supervidorIds: string[] = [];

    text.forEach((i) => {
      var [name, functionalString] = i.split('|').map((part) => part.trim());
      var supervisor = this.originalSupervisors.find(
        (j) =>
          j.functional.toString() === functionalString &&
          j.name.trim() === name.trim()
      );

      if (supervisor) supervidorIds.push(supervisor.id);
    });

    return supervidorIds;
  }

  getAttendantIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [functionalString, name] = text.split('|').map((part) => part.trim());
    var attendant = this.originalAttendants.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name === name
    );

    return attendant ? attendant.id : null;
  }

  clear() {
    this.form.reset();
    this.selectedSupervisors = [];
    this.selectedAttendants = [];
    this.selectedCategories = [];
    this.selectedClients = [];
    this.selectedSkills = [];
  }

  pushModalResponse(title: string, iconPath: string, mainMessage: string) {
    this.titleModalResponse = title;
    this.iconButtonModalResponse = iconPath;
    this.showModalResponse = true;
    this.message = mainMessage;
  }

  handleReportDownloadSuccess(reportFile: Blob | null) {
    const fileAnchor = document.createElement('a');
    const data = new Date();
    fileAnchor.download = `RelatorioAnaliseDeSentimento-${data
      .getDate()
      .toString()
      .padStart(2, '0')}_${(data.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}_${data.getFullYear()}_${data.getHours()}_${data.getMinutes()}.xlsx`;

    fileAnchor.href = window.URL.createObjectURL(reportFile as Blob);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
    this.isLoading = false;
  }

  getAttendantsIdFromText(text: string[]): string[] {
    let attendantIds: string[] = [];

    text.forEach((i) => {
      var [name, functionalString] = i.split('|').map((part) => part.trim());
      var attendant = this.originalAttendants.find(
        (j) =>
          j.functional.toString() === functionalString &&
          j.name.trim() === name.trim()
      );

      if (attendant) attendantIds.push(attendant.id);
    });

    return attendantIds;
  }

  getFilteredShiftsValue(text: string[] | null): string[] | null {
    if (!text || text.length == 0 || (text.length == 1 && text[0] == ''))
      return null;
    return this.form.controls.shifts.value;
  }

  getSkillIdFromText(text: string[]): string[] {
    if (!text || text.length === 0) return [];

    var skills: string[] = [];

    for (let i = 0; i < text.length; i++) {
      if (!text[i] || text[i].trim() === '') return [];

      var [code, cellAndSubCell] = text[i].split(':').map((i) => i.trim());
      var [cell, subCell] = cellAndSubCell.split('—').map((i) => i.trim());

      var skillFiltered = this.originalSkills.find(
        (i) =>
          i.code.toString() === code &&
          i.cell.trim() === cell &&
          i.subCell.trim() === subCell
      );

      if (!skillFiltered || skillFiltered == undefined) {
        var clients = this.form.controls.clients;

        if (
          clients &&
          clients.value &&
          clients.value.length > 0 &&
          clients.value[0] != ''
        ) {
          const filteredClients = this.originalClients.filter((client) =>
            clients.value?.includes(client.name)
          );

          skillFiltered = filteredClients
            .flatMap((client) => client.skills)
            .find(
              (skill) =>
                skill.code.toString() === code &&
                skill.cell.trim() === cell &&
                skill.subCell.trim() === subCell
            );

          if (!skillFiltered) return [];
        }
      }

      if (skillFiltered) skills.push(skillFiltered.id);
    }

    return skills;
  }

  getCategoriesIdFromName(categoryNames: string[]): string[] {
    let categoryIds: string[] = [];

    categoryNames.forEach((categoryName) => {
      let categoryId = this.getCategoryIdFromName(categoryName);
      if (categoryId) categoryIds.push(categoryId);
    });

    return categoryIds;
  }

  getClientsIdFromName(clientNames: string[]): string[] {
    let clientIds: string[] = [];

    clientNames.forEach((i) => {
      let clientId = this.getClientIdFromName(i);
      if (clientId) clientIds.push(clientId);
    });

    return clientIds;
  }

  getClientIdFromName(name: string): string | null {
    if (!name || name.trim() === '') return null;

    var client = this.originalClients.find(
      (i) => i.name.trim() === name.trim()
    );

    return client ? client.id : null;
  }

  getCategoryIdFromName(name: string): string | null {
    if (!name || name.trim() === '') return null;

    var category = this.originalCategories.find(
      (category) => category.name.trim() === name.trim()
    );

    return category ? category.id : null;
  }

  generate() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.error = false;

    var startDate = this.form.controls.initialDate.value ?? '';
    var startDateConvert = new Date(startDate);

    var endDate = this.form.controls.finalDate.value ?? '';
    var endDateConvert = new Date(endDate);

    if (startDate == '' || endDate == '') {
      if (startDate == '') this.form.controls.initialDate.markAsDirty();
      if (endDate == '') this.form.controls.finalDate.markAsDirty();

      this.isLoading = false;
      return;
    }

    var currentDate = new Date();

    if (startDateConvert > endDateConvert) {
      this.isLoading = false;
      this.error = true;
      this.pushModalResponse(
        'Ocorreu um erro ao gerar o relatório',
        'assets/icons/error.svg',
        'A data inicial não pode ser maior que a final.'
      );
      return;
    } else if (startDateConvert > currentDate || endDateConvert > currentDate) {
      this.isLoading = false;
      this.error = true;
      this.pushModalResponse(
        'Ocorreu um erro ao gerar o relatório',
        'assets/icons/error.svg',
        'As datas não podem ser maiores que a data atual.'
      );
      return;
    }

    var filter: CreateSaReportRequest = {} as CreateSaReportRequest;

    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';

    filter.attendants = this.getAttendantsIdFromText(
      this.form.controls.attendants.value || []
    );

    filter.categories = this.getCategoriesIdFromName(
      this.form.controls.categories.value || []
    );

    filter.clients = this.getClientsIdFromName(
      this.form.controls.clients.value || []
    );

    filter.skills = this.getSkillIdFromText(
      this.form.controls.skills.value || []
    );

    filter.shifts = this.getFilteredShiftsValue(
      this.form.controls.shifts.value
    );

    if (
      this.form.controls.supervisors.value &&
      this.form.controls.supervisors.value.length > 0 &&
      this.selectedAttendants &&
      this.selectedAttendants.length <= 0
    ) {
      this.form.get('attendants')?.updateValueAndValidity();
      this.form.patchValue({
        attendants: this.attendants,
      });

      filter.attendants = [];
      filter.attendants = this.getAttendantsIdFromText(this.attendants);
    } else if (
      this.form.controls.supervisors.value &&
      this.form.controls.supervisors.value.length <= 0
    ) {
      this.form.patchValue({
        attendants: [],
      });
      filter.attendants = [];
    }

    this.sa_reportService.create(filter).subscribe({
      next: (data) => {
        if (data.status === 204) {
          this.isLoading = false;
          this.error = true;
          this.pushModalResponse(
            'Ocorreu um erro ao gerar o relatório',
            'assets/icons/error.svg',
            'Não há resultados para gerar o relatório.'
          );
        } else {
          this.handleReportDownloadSuccess(data.body);
          this.router.navigate(['/sa_report/']);
        }
      },
      error: (e: HttpErrorResponse) => {
        this.isLoading = false;
        this.error = true;
        console.error(e);

        this.pushModalResponse(
          'Ocorreu um erro ao gerar o relatório',
          'assets/icons/error.svg',
          'Não foi possível gerar o relatório. Entre em contato com o administrador do sistema.'
        );
      },
    });
  }
}
