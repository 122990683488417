import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { CategoryService } from '../../category/service/category.service';
import { GetCategoriesResponse } from '../../category/service/models/category.service.model';
import {
  GetClientsFilter,
  GetClientsResponse,
  GetSkillRequest,
  GetSkillResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../../user/service/models/user.service.model';
import { UserService } from '../../user/service/user.service';
import { CategoryAnalysisReportGetFilter } from '../service/models/category-analysis-report.service.model';

@Component({
  selector: 'app-category-analysis-report-filter',
  templateUrl: './category-analysis-report-filter.component.html',
  styleUrls: ['./category-analysis-report-filter.component.scss'],
})
export class CategoryAnalysisReportFilterComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public categoryService: CategoryService,
    public clientService: ClientService,
    public skillService: SkillService
  ) {}

  @Output() filterEmmiter: EventEmitter<CategoryAnalysisReportGetFilter> =
    new EventEmitter<CategoryAnalysisReportGetFilter>();

  isLoading: boolean = false;
  isOpen: boolean = true;
  isValidPeriod: boolean = true;

  supervisors: string[] = [];
  selectedSupervisors: string[] = [];
  originalSupervisors: GetUserResponse[] = [];
  loadingSupervisors: boolean = false;

  attendants: string[] = [];
  selectedAttendants: string[] = [];
  originalAttendants: GetUserResponse[] = [];

  categories: string[] = [];
  selectedCategories: string[] = [];
  originalCategories: GetCategoriesResponse[] = [];

  clients: string[] = [];
  selectedClients: string[] = [];
  originalClients: GetClientsResponse[] = [];

  skills: string[] = [];
  selectedSkills: string[] = [];
  originalSkills: GetSkillResponse[] = [];

  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  selectedShifts: string[] = [];
  loadingShifts = false;

  form = this.formBuilder.group({
    initialDate: ['', Validators.required],
    finalDate: ['', Validators.required],
    attendants: [['']],
    supervisors: [['']],
    categories: [['']],
    clients: [['']],
    skills: [['']],
    shifts: [['']],
  });

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit() {
    this.form.controls.supervisors.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0) {
          var supervisorIds = this.getSupervisorsIdFromText(value);

          var supervisors = this.originalSupervisors.filter((s) =>
            supervisorIds.includes(s.id)
          );

          this.originalAttendants = supervisors
            .map((s) => s.attendants.map((a) => a))
            .flat();

          this.attendants = this.originalAttendants.map(
            (a) => a.name.trim() + ' | ' + a.functional
          );
        } else {
          this.attendants = [];
          this.selectedAttendants = [];
        }
      }
    );

    this.form.controls.clients.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0 && value[0] !== '') {
          var clientIds = this.getClientsIdFromName(value);

          var clients = this.originalClients.filter((s) =>
            clientIds.includes(s.id)
          );

          const filteredSkills = clients
            .map((s) => s.skills.map((a) => a))
            .flat();

          this.skills = filteredSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );

          this.selectedSkills = [];
        } else {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
        }

        if (
          !this.form.controls.clients.value ||
          this.form.controls.clients.value.length === 0 ||
          (this.form.controls.clients.value.length === 1 &&
            this.form.controls.clients.value[0] === '')
        ) {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
        }
      }
    );

    this.populateSupervisors();
    this.populateCategories();
    this.populateClients();
    this.populateSkills();
  }

  isAttendantsDisabled(): boolean {
    var supervisors = this.form.controls.supervisors;
    return (
      !supervisors ||
      !supervisors.value ||
      supervisors.value.length == 0 ||
      (supervisors.value.length == 1 && supervisors.value[0] === '')
    );
  }

  getFilteredShiftsValue(text: string[] | null): string[] | null {
    if (!text || text.length == 0 || (text.length == 1 && text[0] == ''))
      return null;
    return this.form.controls.shifts.value;
  }

  getAttendantsPlaceholder(): string {
    var supervisors = this.form.controls.supervisors;
    if (supervisors && supervisors.value && supervisors.value.length > 0 && supervisors.value[0] !== '') {
      return this.attendants.length > 0
        ? 'Selecione múltiplos atendentes'
        : 'Nenhum atendente disponível';
    }
    return 'Selecione múltiplos atendentes';
  }

  isSkillsDisabled(): boolean {
    const clients = this.form.controls.clients.value;
    return !clients || (clients.length === 0 && this.skills.length === 0);
  }

  getSkillsPlaceholder(): string {
    const clients = this.form.controls.clients.value;
    if (clients && clients.length > 0 && clients[0] !== '') {
      return this.skills.length > 0
        ? 'Selecione múltiplas skills'
        : 'Nenhuma skill disponível';
    }
    return 'Selecione múltiplas skills';
  }

  populateClients() {
    this.clients = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clients = data.items.map((i) => i.name.trim());
        },
      });
  }

  populateSkills() {
    this.skills = [];

    this.skillService.get({ active: true } as GetSkillRequest).subscribe({
      next: (data) => {
        this.originalSkills = data;
        this.skills = data.map(
          (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
        );
      },
    });
  }

  populateSupervisors() {
    this.loadingSupervisors = true;
    this.supervisors = [];

    this.userService
      .get({
        profiles: ['SUPERVISOR'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalSupervisors = data.items;
          this.supervisors = data.items.map(
            (supervisor) =>
              supervisor.name.trim() + ' | ' + supervisor.functional
          );
          this.loadingSupervisors = false;
        },
      });
  }

  populateCategories() {
    this.categories = [];

    this.categoryService.getAll().subscribe({
      next: (data) => {
        this.originalCategories = data;
        this.categories = data.map((category) => category.name);
      },
    });
  }

  clear() {
    this.form.reset();
    this.selectedCategories = [];
    this.selectedSupervisors = [];
    this.selectedAttendants = [];
    this.selectedClients = [];
    this.selectedSkills = [];
  }

  generate() {
    let filter: CategoryAnalysisReportGetFilter =
      {} as CategoryAnalysisReportGetFilter;

    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';

    this.isValidPeriod = this.isValidDate(filter.startDate, filter.endDate);

    if (!this.isValidPeriod) {
      this.isLoading = false;
      return;
    }

    filter.attendants = this.getAttendantsIdFromText(
      this.form.controls.attendants.value || []
    );

    filter.categories = this.getCategoriesIdFromName(
      this.form.controls.categories.value || []
    );

    filter.clients = this.getClientsIdFromName(
      this.form.controls.clients.value || []
    );

    filter.skills = this.getSkillIdFromText(
      this.form.controls.skills.value || []
    );

    filter.shifts = this.getFilteredShiftsValue(
      this.form.controls.shifts.value
    );

    if (
      this.form.controls.supervisors.value &&
      this.form.controls.supervisors.value.length > 0 &&
      this.selectedAttendants &&
      this.selectedAttendants.length <= 0
    ) {
      this.form.get('attendants')?.updateValueAndValidity();
      this.form.patchValue({
        attendants: this.attendants,
      });

      filter.attendants = [];
      filter.attendants = this.getAttendantsIdFromText(this.attendants);
    } else if (
      this.form.controls.supervisors.value &&
      this.form.controls.supervisors.value.length <= 0
    ) {
      this.form.patchValue({
        attendants: [],
      });
      filter.attendants = [];
    }

    this.filterEmmiter.emit(filter);

    this.isOpen = false;
  }

  getSkillIdFromText(text: string[]): string[] | null {
    if (!text || text.length === 0) return null;

    var skills: string[] = [];

    for (let i = 0; i < text.length; i++) {
      if (!text[i] || text[i].trim() === '') return null;

      var [code, cellAndSubCell] = text[i].split(':').map((i) => i.trim());
      var [cell, subCell] = cellAndSubCell.split('—').map((i) => i.trim());

      var skillFiltered = this.originalSkills.find(
        (i) =>
          i.code.toString() === code &&
          i.cell.trim() === cell &&
          i.subCell.trim() === subCell
      );

      if (!skillFiltered || skillFiltered == undefined) {
        var clients = this.form.controls.clients;

        if (
          clients &&
          clients.value &&
          clients.value.length > 0 &&
          clients.value[0] != ''
        ) {
          const filteredClients = this.originalClients.filter((client) =>
            clients.value?.includes(client.name)
          );

          skillFiltered = filteredClients
            .flatMap((client) => client.skills)
            .find(
              (skill) =>
                skill.code.toString() === code &&
                skill.cell.trim() === cell &&
                skill.subCell.trim() === subCell
            );

          if (!skillFiltered) return null;
        }
      }

      if (skillFiltered) skills.push(skillFiltered.id);
    }

    return skills;
  }

  getSupervisorsIdFromText(text: string[]): string[] {
    let supervidorIds: string[] = [];

    text.forEach((i) => {
      var [name, functionalString] = i.split('|').map((part) => part.trim());
      var supervisor = this.originalSupervisors.find(
        (j) =>
          j.functional.toString() === functionalString &&
          j.name.trim() === name.trim()
      );

      if (supervisor) supervidorIds.push(supervisor.id);
    });

    return supervidorIds;
  }

  getAttendantsIdFromText(text: string[]): string[] {
    let attendantIds: string[] = [];

    text.forEach((i) => {
      var [name, functionalString] = i.split('|').map((part) => part.trim());
      var attendant = this.originalAttendants.find(
        (j) =>
          j.functional.toString() === functionalString &&
          j.name.trim() === name.trim()
      );

      if (attendant) attendantIds.push(attendant.id);
    });

    return attendantIds;
  }

  getCategoriesIdFromName(categoryNames: string[]): string[] {
    let categoryIds: string[] = [];

    categoryNames.forEach((categoryName) => {
      let categoryId = this.getCategoryIdFromName(categoryName);
      if (categoryId) categoryIds.push(categoryId);
    });

    return categoryIds;
  }

  getClientsIdFromName(clientNames: string[]): string[] {
    let clientIds: string[] = [];

    clientNames.forEach((i) => {
      let clientId = this.getClientIdFromName(i);
      if (clientId) clientIds.push(clientId);
    });

    return clientIds;
  }

  getClientIdFromName(name: string): string | null {
    if (!name || name.trim() === '') return null;

    var client = this.originalClients.find(
      (i) => i.name.trim() === name.trim()
    );

    return client ? client.id : null;
  }

  getCategoryIdFromName(name: string): string | null {
    if (!name || name.trim() === '') return null;

    var category = this.originalCategories.find(
      (category) => category.name.trim() === name.trim()
    );

    return category ? category.id : null;
  }

  isFormComplete() {
    return (
      this.form.controls.initialDate.valid && this.form.controls.finalDate.valid
    );
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  resetPeriod() {
    this.isValidPeriod = this.isValidDate(
      this.form.controls.initialDate.value ?? '',
      this.form.controls.finalDate.value ?? ''
    );
  }

  isValidDate(startDate: string, endDate: string) {
    return startDate != '' && endDate != '';
  }
}
